import { ProductSingleProps } from '../../../../queries/archive';

export const getFirstVariationWithQuantity = (product?: ProductSingleProps) => {
  if (product?.type === 'SIMPLE') {
    return null;
  }

  return product?.variations?.nodes?.find(
    (variation) =>
      variation.stockQuantity > 0 &&
      !variation.attributes.nodes.some((attr) => attr?.value === 'custom'),
  );
};
