import { REFRESH_JWT_AUTH_TOKEN } from '../queries/mutations/refreshJwtAuthToken';

import { isTokenExpired } from '../lib/helpers/isTokenExpired';
import { getLocalValues, setLocalValue } from '../lib/helpers/localStorageHelpers';
import { logError } from '../lib/helpers/logError';

export const handleWooSession = (token: string | null) => {
  const { wooSession } = getLocalValues();

  if (!token || wooSession === token) {
    return;
  }

  setLocalValue('wooSession', token);
};

interface RequestHeadersType {
  authorization?: string;
  'woocommerce-session'?: string;
}

export const attachRequestHeaders = () => {
  const requestHeaders: RequestHeadersType = {};
  const { authToken, wooSession } = getLocalValues();

  if (authToken) {
    requestHeaders['authorization'] = `Bearer ${authToken}`;
  }

  if (wooSession && !isTokenExpired(wooSession)) {
    requestHeaders['woocommerce-session'] = `Session ${wooSession}`;
  }

  return requestHeaders;
};

export const getJWTRefreshToken = (): string => {
  const { refreshToken } = getLocalValues();

  return refreshToken;
};

let getNewTokenPromise: Promise<string> | null = null;

export const getNewToken = async () => {
  if (getNewTokenPromise) {
    // If there's already a request in progress, return the existing promise.
    return getNewTokenPromise;
  }

  try {
    const refreshToken = await getJWTRefreshToken();
    getNewTokenPromise = fetch(process.env.REACT_APP_GRAPHQL_API as RequestInfo, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        variables: {
          refreshToken: refreshToken,
        },
        query: REFRESH_JWT_AUTH_TOKEN.loc?.source.body,
      }),
    })
      .then((res) => res.json())
      .then((resNew) => {
        if (resNew.errors && resNew.errors[0].message.includes('refresh token is invalid')) {
          localStorage.removeItem('VALENTINE_COUTURE_USER');
          window.location.reload();
          throw new Error('Invalid refresh token');
        }
        // Update the local storage with the new token.
        const newToken = resNew.data.refreshJwtAuthToken.authToken;
        setLocalValue('authToken', newToken);
        // Reset the promise to allow future requests to trigger a new fetch.
        getNewTokenPromise = null;
        return newToken;
      });

    return getNewTokenPromise;
  } catch (error) {
    logError(error);
    getNewTokenPromise = null; // Reset the promise in case of an error.
    throw error;
  }
};
