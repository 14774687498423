import React, { useEffect, useState } from 'react';
import { Alert, AlertType } from '../../../components/Notifications/Alert';
import { useRouter } from '../../hooks/useRouter';

import {
  NotificationContext,
  NotificationContextType
} from './NotificationContext';
import { formatGraphqlErrors } from '../../helpers/formatGraphqlErrors';

interface NotificationContextProviderProps {
  children: React.ReactNode | null;
}

export interface NotificationState {
  type: AlertType;
  message: string;
  className?: string;
  autoClose: boolean;
}

let timeout: NodeJS.Timeout | undefined;

export const NotificationContextProvider = (
  props: NotificationContextProviderProps
) => {
  const autoRemoveTime = 5000;
  const router = useRouter();
  const [notifications, setNotifications] = useState<NotificationState[]>([]);

  useEffect(() => {
    setNotifications([]);
  }, [router.location]);

  const warning = (
    message: string,
    autoClose: boolean = false,
    className?: string
  ) => {
    add('warning', message, autoClose, className);
  };

  const success = (
    message: string,
    autoClose: boolean = false,
    className?: string
  ) => {
    add('success', message, autoClose, className);
  };

  const danger = (
    message: string,
    autoClose: boolean = false,
    className?: string
  ) => {
    const formatedMessage = formatGraphqlErrors(message);

    add('danger', formatedMessage, autoClose, className);
  };

  const notificationExists = (message: string) => {
    return !!notifications.find(
      (notification: NotificationState) => notification.message === message
    );
  };

  const add = (
    type: AlertType,
    message: string,
    autoClose: boolean,
    className?: string
  ) => {
    window.scrollTo(0, 0);

    if (notificationExists(message)) {
      return;
    }

    setNotifications([
      ...notifications,
      {
        type,
        message,
        autoClose,
        className
      }
    ]);

    if (autoClose) {
      autoRemove(message);
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  };

  const autoRemove = (message: string) => {
    timeout = setTimeout(() => {
      remove(message);
    }, autoRemoveTime);
  };

  const remove = (message: string) => {
    setNotifications(
      notifications.filter(
        (notification: NotificationState) => notification.message !== message
      )
    );
  };

  const removeAll = () => {
    setNotifications([]);
  };

  const context: NotificationContextType = {
    notifications,

    warning,
    success,
    danger,
    remove,
    removeAll
  };

  return (
    <NotificationContext.Provider value={context}>
      {notifications.map((notification: NotificationState, index: number) => {
        return (
          <Alert
            key={`${index}-${notification.message}`}
            type={notification.type}
            message={notification.message}
            className={notification.className}
            autoClose={notification.autoClose}
            onClose={() => remove(notification.message)}
          />
        );
      })}
      {props.children}
    </NotificationContext.Provider>
  );
};
