import React from 'react';
import cn from 'classnames';
import { useQuery } from '@apollo/client';
import { GET_PAGE_BY_SLUG, GetPageBySlugResponse } from '../../../queries/generalQueries';
import { ApolloErrorGuard } from '../../../components/shared/ApolloErrorGuard/ApolloErrorGuard';
import { HandleLoadingState } from '../../../components/shared/HandleLoadingState/HandleLoadingState';
import { ContentPagePlaceholder } from './ContentPagePlaceholder';
import { DefaultPage } from '../../../pages/DefaultPage/DefaultPage';
import { useParams } from 'react-router-dom';
import { PageBanner } from '../PageBanner/PageBanner';

import './ContentPage.scss';

export const ContentPage = () => {
  const params = useParams<{ slug: string }>();
  const { loading, error, data } = useQuery<GetPageBySlugResponse>(GET_PAGE_BY_SLUG, {
    variables: {
      slug: params?.slug,
    },
  });

  const content = data?.pageBy;
  const image = content?.featuredImage?.node.sourceUrl;

  return (
    <div
      className={cn('ContentPage ContentPage__Wordpress', !!image && 'ContentPage--with-banner')}
    >
      <HandleLoadingState loading={loading} loadingPlaceholder={<ContentPagePlaceholder />}>
        <ApolloErrorGuard error={error}>
          {image && <PageBanner imageUrl={image} title={content?.title} />}
          <DefaultPage title={image ? undefined : content?.title} content={content?.content} />
        </ApolloErrorGuard>
      </HandleLoadingState>
    </div>
  );
};
