import React from 'react';
import cs from 'classnames';
import shortid from 'shortid';

import './Quantity.scss';

interface Props {
  loading?: boolean;
  disabled?: boolean;
  disableDecrement?: boolean;
  disableIncrement?: boolean;
  hideMobile?: boolean;
  maxQuantity?: number;
  quantity: number;
  onChange: (value: number) => void;
}

export const Quantity = (props: Props) => {
  const maxQty = props.maxQuantity;
  const qty = props.quantity || 1;

  const decrement = () => {
    if (qty === 1) {
      props.onChange(1);
      return;
    }

    props.onChange(qty - 1);
  };

  const increment = () => {
    const newQty = qty + 1;
    if (maxQty && newQty > maxQty) {
      props.onChange(maxQty);
      return;
    }
    props.onChange(newQty);
  };

  return (
    <div
      className={cs('Quantity', {
        'Quantity--hide_mobile': props.hideMobile,
      })}
    >
      <div className='Quantity__input'>
        <button
          className='minus'
          disabled={props.disabled || props.disableDecrement}
          onClick={decrement}
        >
          -
        </button>

        <input min='1' type='number' value={qty} max={maxQty} onChange={() => {}} />

        <button
          className='plus'
          onClick={increment}
          disabled={!props.maxQuantity || props.disabled || props.disableIncrement}
        >
          +
        </button>
      </div>
      <div className='Quantity__select'>
        <QuantitySelect
          disabled={props.disabled}
          max={maxQty}
          value={qty}
          onChange={(value) => props.onChange(value)}
        />
      </div>
    </div>
  );
};

interface QuantitySelectProps {
  disabled?: boolean;
  max?: number;
  value: number;
  onChange: (quantity: number) => void;
}

export const QuantitySelect = (props: QuantitySelectProps) => {
  const options = props.max
    ? Array.from({ length: props.max }, (v, i) => {
        return { key: shortid.generate(), value: i + 1 };
      })
    : null;

  const handleChange = (newValue: number) => {
    props.onChange(newValue);
  };

  return (
    <label>
      <select
        disabled={props.disabled || !options}
        id='qty'
        name='qty'
        value={props.value}
        onChange={(e) => handleChange(parseInt(e.target.value))}
      >
        {options?.map((item) => (
          <option value={item.value} key={`${item.key}-quantity`}>
            {item.value}
          </option>
        ))}
      </select>
    </label>
  );
};
