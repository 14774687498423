import React, { useContext } from 'react';

export interface MenuSidebarType {
  cart: boolean;
  wishtlist: boolean;
  toggleSidebar: (name: 'cart' | 'wishlist') => void;
  openSidebar: (name: 'cart' | 'wishlist') => void;
  closeSidebar: (name: 'cart' | 'wishlist') => void;
}

const defaultMenuSidebar: MenuSidebarType = {
  cart: false,
  wishtlist: false,
  toggleSidebar: () => {},
  openSidebar: () => {},
  closeSidebar: () => {}
};

export const MenuSidebar = React.createContext<MenuSidebarType>(
  defaultMenuSidebar
);

export function useMenuSidebar() {
  return useContext(MenuSidebar);
}
