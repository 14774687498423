import { useFormik, FormikHelpers, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { LoginFields } from '../../pages/Login/Login';
import { formValidation } from '../translation/strings';

interface UseLoginFormOptions {
  initialValues?: LoginFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<LoginFields>
  ) => Promise<any>;
}

export const useLoginFormik = (opts: UseLoginFormOptions) => {
  const {t}= useTranslation();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required(t(formValidation.email)),
    password: Yup.string().required(t(formValidation.password))
  });

  return useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: LoginSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    }
  });
};

export type LoginFormik = ReturnType<typeof useLoginFormik>;
