import React from 'react';
import cs from 'classnames';

import { IconSvg, IconDefinition } from './Icon.generated';

import './Icon.scss';

export * from './Icon.generated';

interface Props {
  icon?: IconDefinition;
  alt?: string;
  style?: React.CSSProperties;
  className?: string;
  spin?: boolean;
  onClick?: () => void;
}

export function Icon(props: Props) {
  if (!props.icon) {
    return null;
  }
  return (
    <div
      style={props.style}
      onClick={props.onClick}
      className={cs('Icon', props.spin ? 'Icon--spin' : undefined, props.className)}
    >
      {IconSvg[props.icon]}
    </div>
  );
}
