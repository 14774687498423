import React, { useEffect, useState } from 'react';
import { ShopContextType, ShopContext } from './ShopContext';

interface Props {
  data?: ShopContextType;
  children: React.ReactNode;
}

export const ShopContextProvider = (props: Props) => {
  const [isOpened, setIsOpened] = useState(false);
  const [data, setData] = useState<ShopContextType | undefined>(props.data || undefined);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (isOpened) {
      document.body.style.overflow = 'hidden';
      document.getElementById('App')?.classList.add('App--show-backdrop');
    }
    return () => {
      document.body.style.overflow = originalStyle;
      document.getElementById('App')?.classList.remove('App--show-backdrop');
    };
  }, [isOpened]);

  const openSidebar = () => {
    setIsOpened(true);
  };

  const closeSidebar = () => {
    setIsOpened(false);
  };

  const toggleSidebar = () => {
    setIsOpened(!isOpened);
  };

  const context: ShopContextType = {
    data: {
      category: data?.data?.category,
      subCategory: data?.data?.subCategory,
      shopType: data?.data?.shopType,
      shopLinkPrefix: data?.data?.shopLinkPrefix,

      order: data?.data?.order,
      slug: data?.data?.slug,
    },
    handlers: data?.handlers,
    sidebar: {
      isOpened,
      closeSidebar,
      openSidebar,
      toggleSidebar,
    },
  };

  return <ShopContext.Provider value={context}>{props.children}</ShopContext.Provider>;
};
