import React, { useEffect } from 'react';
import Plyr from 'plyr-react';
import { useQuery } from '@apollo/client';
import { HomeVideoLoader } from './HomeVideoLoader';
import { ApolloErrorGuard } from '../../shared/ApolloErrorGuard/ApolloErrorGuard';
import { HandleLoadingState } from '../../shared/HandleLoadingState/HandleLoadingState';
import { HomeVideoResponse, getHomeVideo } from '../../../queries/home';
import { Button } from '../../shared/Button/Button';

import './HomeVideo.scss';
import { useAuthContext } from '../../../lib/context/AuthContext/AuthContext';

export const HomeVideo = () => {
  const { setIsLoadingData } = useAuthContext();
  const { data, error, loading } = useQuery<HomeVideoResponse>(getHomeVideo);
  const video = data?.generalOption?.homeSettings?.banner?.node?.mediaItemUrl;
  const button = data?.generalOption?.homeSettings?.bannerButton;

  useEffect(() => {
    setIsLoadingData(loading);
  }, [loading]);

  const plyrProps = {
    source: {
      type: 'video',
      sources: [
        {
          src: `${video}`,
          type: 'video/mp4',
        },
      ],
    },
    options: {
      autoplay: true,
      muted: true,
      loop: { active: true },
      controles: [],
      hideControls: true,
      clickToPlay: false,
      fullscreen: { enabled: false },
    },
  } as any;

  if (!video) return null;

  return (
    <div className='HomeVideo'>
      <HandleLoadingState loading={loading} loadingPlaceholder={<HomeVideoLoader />}>
        <ApolloErrorGuard error={error}>
          <>
            <Plyr {...plyrProps} />
            {button && (
              <Button to={button?.url} className='HomeVideo__button'>
                {button?.title}
              </Button>
            )}
          </>
        </ApolloErrorGuard>
      </HandleLoadingState>
    </div>
  );
};
