/* eslint-disable camelcase */
import React from 'react';
import * as Yup from 'yup';
import cn from 'classnames';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { GET_PAGE_BY_SLUG, GetPageBySlugResponse } from '../../queries/generalQueries';
import {
  CONTACT_FORM,
  ContactFormInput,
  ContactFormResponse,
} from '../../queries/mutations/contactMutation';
import { fields, formValidation } from '../../lib/translation/strings';
import { ApolloErrorGuard } from '../../components/shared/ApolloErrorGuard/ApolloErrorGuard';
import { HandleLoadingState } from '../../components/shared/HandleLoadingState/HandleLoadingState';
import { ContactForm } from './ContactForm';
import { HeadingTitle } from '../../components/shared/HeadingTitle/HeadingTitle';
import { BulkLoader } from '../../components/shared/BulkLoader/BulkLoader';
import { ThemeContainer } from '../../components/shared/ThemeContainer/ThemeContainer';
import { logError } from '../../lib/helpers/logError';
import { ContactPlaceholder } from './ContactLoader';
import { PageBanner } from '../../components/shared/PageBanner/PageBanner';

import './Contact.scss';

export const Contact = () => {
  const { t } = useTranslation();
  const { data, loading, error } = useQuery<GetPageBySlugResponse>(GET_PAGE_BY_SLUG, {
    variables: {
      slug: 'contact-us',
    },
  });
  const [contactFormSubmission, contactForm] = useMutation<ContactFormResponse, ContactFormInput>(
    CONTACT_FORM,
  );

  const formik = useFormik<ContactFormInput>({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      city: '',
      country: '',
      subject: '',
      message: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required(t(formValidation.firstName)),
      last_name: Yup.string().required(t(formValidation.lastName)),
      email: Yup.string().email().required(t(formValidation.email)),
      subject: Yup.string().required(t(formValidation.subject)),
      message: Yup.string().required(t(formValidation.message)),
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        await contactFormSubmission({
          variables: values,
        });

        formikHelpers.resetForm();
      } catch (error) {
        logError(error);
      }
    },
  });

  const page = data?.pageBy;
  const image = page?.featuredImage?.node?.sourceUrl;
  return (
    <div className={cn('Contact', image && 'Contact--with-banner')}>
      {image && <PageBanner imageUrl={image} title={page.title} />}
      <ThemeContainer>
        <HandleLoadingState
          loading={loading}
          loadingPlaceholder={
            <BulkLoader length={1}>
              <ContactPlaceholder />
            </BulkLoader>
          }
        >
          <ApolloErrorGuard error={error}>
            <>
              {!image && <HeadingTitle title={t(fields.contact)} />}
              <div className='DefaultPage__content'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: page?.content || '',
                  }}
                />
              </div>

              <ContactForm
                formik={formik}
                loading={loading || contactForm.loading}
                success={!!contactForm?.data}
                error={contactForm?.error?.message}
              />
            </>
          </ApolloErrorGuard>
        </HandleLoadingState>
      </ThemeContainer>
      <div className='Contact__map'>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6129.18676224232!2d21.16482785118143!3d42.65214121433446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549f52a76cbe35%3A0x42a9cc5930f51d21!2sValentine%20Couture!5e1!3m2!1sen!2s!4v1710780200913!5m2!1sen!2s'
          loading='lazy'
        ></iframe>
      </div>
    </div>
  );
};
