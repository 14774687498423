import { ProductSingleVariationProps } from '../../../../queries/archive';

export const getVariationAttributeValue = (
  variation: ProductSingleVariationProps,
  attrName: string
) => {
  return (
    variation.attributes.nodes.find(attr => attr.name === attrName)?.value ||
    null
  );
};
