import React from 'react';
import ContentLoader from 'react-content-loader';

export const OrdersLoader = () => (
    <ContentLoader
        speed={2}
        width={476}
        height={124}
        viewBox="0 0 476 124"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="168" y="14" rx="3" ry="3" width="88" height="6"/>
        <rect x="168" y="32" rx="3" ry="3" width="150" height="6"/>
        <rect x="168" y="50" rx="3" ry="3" width="150" height="6"/>
        <rect x="168" y="69" rx="3" ry="3" width="150" height="6"/>
        <rect x="168" y="89" rx="3" ry="3" width="88" height="6"/>
        <rect x="5" y="2" rx="0" ry="0" width="140" height="129"/>
    </ContentLoader>
);