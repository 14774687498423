import React from 'react';
import { NavLink } from 'react-router-dom';
import { HeadingTitle } from '../HeadingTitle/HeadingTitle';

import './InfoMessage.scss';

interface Props {
  title: string;
  buttonTitle?: string;
  buttonLink?: string;
}

export const InfoMessage = ({ title, buttonLink, buttonTitle }: Props) => {
  return (
    <div className='InfoMessage'>
      <HeadingTitle title={title} />
      {buttonTitle && buttonLink && (
        <NavLink className='InfoMessage__link' to={buttonLink || '/'}>
          {buttonTitle}
        </NavLink>
      )}
    </div>
  );
};
