import React from 'react';
import queryString from 'query-string';
import { useRouter } from '../../../lib/hooks/useRouter';
import { ShopSelect, ShopSelectOptions } from './ShopSelect/ShopSelect';

import './ShopAttributes.scss';
import { ProductTaxonomyEnum } from '../../../types/generalTypes';

export interface AttributesOption {
  name: string;
  label: string;
  value: string;
  count?: string | number;
  image: string | null;
}

interface Props {
  loading?: boolean;
  isLastAttribute?: boolean;
  attributeTitle?: string;
  attribute: ProductTaxonomyEnum;
  options: AttributesOption[];
  onChange: (attr: string, selectedValues?: ShopSelectOptions) => void;
}

export const ShopAttributes = (props: Props) => {
  const router = useRouter();
  const selected = queryString.parse(router.location.search)[props.attribute] as string;

  const getLabelByAttribute = ({ image, label, value, count }: AttributesOption) => {
    if (props.attribute === ProductTaxonomyEnum.PACOLOR) {
      return (
        <span className='ShopAttributes__color'>
          {!image ? (
            <div style={{ backgroundColor: `#${value}` }} />
          ) : (
            <div style={{ backgroundImage: `url(${image})` }} />
          )}
        </span>
      );
    }
    if (props.attribute === ProductTaxonomyEnum.PASIZE) {
      return <span className='ShopAttributes__size'>{label}</span>;
    }
    return (
      <>
        <span>{label}</span>
        <small>({count})</small>
      </>
    );
  };

  const options = props.options
    ? props.options.map((o) => ({
        value: o.value,
        label: getLabelByAttribute(o),
      }))
    : [];

  const selectedOptions = options?.filter((option) => selected?.split(',').includes(option.value));

  return (
    <div className='ShopAttributes'>
      <ShopSelect
        attribute={props.attribute}
        loading={props.loading}
        label={props.attributeTitle || ''}
        options={options}
        selected={selectedOptions}
        onSelect={(selectOptions) => props.onChange(props.attribute, selectOptions)}
      />
    </div>
  );
};
