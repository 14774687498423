import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Input, FormFeedback, Row } from 'reactstrap';
import { fields, formInputs } from '../../../lib/translation/strings';
import { HeadingTitle } from '../../shared/HeadingTitle/HeadingTitle';
import { ProfileAddressFormik } from '../../../lib/formik/profile/useProfileAddressFormik';
import { SelectState } from '../../shared/SelectState/SelectState';
import { SelectCountry } from '../../shared/SelectCountry/SelectCountry';

interface Props {
  formik: ProfileAddressFormik;
}

export const ProfileAddressBilling = (props: Props) => {
  const { formik } = props;
  const { t } = useTranslation();

  return (
    <div className='ProfileAddressBilling'>
      <Row>
        <Col xs='12' sm='12'>
          <HeadingTitle title={t(fields.billingAddress)} />
        </Col>

        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Label for='firstName'>{t(formInputs.name)}</Label>
            <Input
              id='firstName'
              type='text'
              name='billing.firstName'
              onChange={formik.handleChange}
              value={formik.values.billing.firstName || ''}
              invalid={!!formik.errors.billing?.firstName && formik.touched.billing?.firstName}
            />
            <FormFeedback>{formik.errors.billing?.firstName}</FormFeedback>
          </FormGroup>
        </Col>

        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Label for='lastName'>{t(formInputs.lastName)}</Label>
            <Input
              id='lastName'
              type='text'
              name='billing.lastName'
              onChange={formik.handleChange}
              value={formik.values.billing.lastName || ''}
              invalid={!!formik.errors.billing?.lastName && formik.touched.billing?.lastName}
            />
            <FormFeedback>{formik.errors.billing?.lastName}</FormFeedback>
          </FormGroup>
        </Col>

        <Col xs='12'>
          <FormGroup className='form-group'>
            <Label for='address1'>{t(formInputs.address)}</Label>
            <Input
              id='address1'
              type='text'
              name='billing.address1'
              onChange={formik.handleChange}
              placeholder={t(formInputs.address)}
              value={formik.values.billing.address1 || ''}
              invalid={!!formik.errors.billing?.address1 && formik.touched.billing?.address1}
            />
            <FormFeedback>{formik.errors.billing?.address1}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Label for='city'>{t(fields.city)}</Label>
            <Input
              id='city'
              type='text'
              name='billing.city'
              onChange={formik.handleChange}
              placeholder={t(fields.city)}
              value={formik.values.billing.city || ''}
              invalid={!!formik.errors.billing?.city && formik.touched.billing?.city}
            />
            <FormFeedback>{formik.errors.billing?.city}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Label for='postcode'>{t(fields.zipCode)}</Label>
            <Input
              id='postcode'
              type='text'
              name='billing.postcode'
              onChange={formik.handleChange}
              placeholder={t(fields.zipCode)}
              value={formik.values.billing.postcode || ''}
              invalid={!!formik.errors.billing?.postcode && formik.touched.billing?.postcode}
            />
            <FormFeedback>{formik.errors.billing?.postcode}</FormFeedback>
          </FormGroup>
        </Col>

        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Label for='state'>{t(fields.state)}</Label>
            <SelectState
              id='state'
              name='state'
              country={formik.values.billing.country}
              hasError={!!formik.errors.billing?.state && formik.touched.billing?.state}
              errorMessage={formik.errors.billing?.state}
              onSelect={(state: string) => {
                formik.setFieldValue('billing.state', state);
              }}
              initialValue={formik.values.billing.state}
            />
          </FormGroup>
        </Col>

        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Label for='country'>{t(fields.country)}</Label>
            <SelectCountry
              id='country'
              name='country'
              hasError={!!formik.errors.billing?.country && formik.touched.billing?.country}
              errorMessage={formik.errors.billing?.country}
              onSelect={(country: string) => {
                formik.setFieldValue('billing.country', country);
              }}
              initialValue={formik.values.billing.country}
            />
          </FormGroup>
        </Col>

        <Col xs='12'>
          <FormGroup className='form-group'>
            <Label for='phone'>{t(formInputs.phoneNumber)}</Label>
            <Input
              id='phone'
              type='text'
              name='billing.phone'
              onChange={formik.handleChange}
              value={formik.values.billing.phone || ''}
              invalid={!!formik.errors.billing?.phone && formik.touched.billing?.phone}
            />
            <FormFeedback>{formik.errors.billing?.phone}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};
