import React from 'react';
import ContentLoader from 'react-content-loader';
import { BulkLoader } from '../../../components/shared/BulkLoader/BulkLoader';
import './Menu.scss';

export const MenuLoader = () => {
  return (
    <div className='Menu-dropdown-wrapper'>
      <div className='Menu-dropdown-left'>
        <h6>
          <ContentLoader
            speed={2}
            width={140}
            height={20}
            viewBox='0 0 140 20'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
          >
            <rect x='0' y='0' rx='0' ry='0' width='140' height='20' />
          </ContentLoader>
        </h6>
        <ul className='Menu-dropdown__list'>
          <BulkLoader length={2}>
            <li className='Menu-dropdown__list-item'>
              <div className='Menu-dropdown__list-item-title'>
                <ContentLoader
                  speed={2}
                  width={200}
                  height={24}
                  viewBox='0 0 200 24'
                  backgroundColor='#f3f3f3'
                  foregroundColor='#ecebeb'
                >
                  <rect x='0' y='0' rx='0' ry='0' width='200' height='24' />
                </ContentLoader>
              </div>
            </li>
          </BulkLoader>
        </ul>
      </div>
      <div className='Menu-dropdown-right'>
        <BulkLoader length={2}>
          <div className='Menu-dropdown__box'>
            <div className='Menu-dropdown__box-image'>
              <ContentLoader
                speed={2}
                width={240}
                height={280}
                viewBox='0 0 240 280'
                backgroundColor='#f3f3f3'
                foregroundColor='#ecebeb'
              >
                <rect x='0' y='0' rx='0' ry='0' width='240' height='280' />
              </ContentLoader>
            </div>
            <div className='Menu-dropdown__box-content'>
              <h5>
                <ContentLoader
                  speed={2}
                  width={200}
                  height={20}
                  viewBox='0 0 200 20'
                  backgroundColor='#f3f3f3'
                  foregroundColor='#ecebeb'
                >
                  <rect x='0' y='0' rx='0' ry='0' width='200' height='20' />
                </ContentLoader>
              </h5>
              <span>
                <ContentLoader
                  speed={2}
                  width={100}
                  height={16}
                  viewBox='0 0 100 16'
                  backgroundColor='#f3f3f3'
                  foregroundColor='#ecebeb'
                >
                  <rect x='0' y='0' rx='0' ry='0' width='100' height='16' />
                </ContentLoader>
              </span>
            </div>
          </div>
        </BulkLoader>
      </div>
    </div>
  );
};
