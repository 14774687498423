import React from 'react';
import { useQuery } from '@apollo/client';
import { DefaultPage } from '../DefaultPage/DefaultPage';
import { BlogPostResponse, GET_BLOG_POST } from '../../queries/blogQueries';
import { HandleLoadingState } from '../../components/shared/HandleLoadingState/HandleLoadingState';
import { ApolloErrorGuard } from '../../components/shared/ApolloErrorGuard/ApolloErrorGuard';
import { Col, Container, Row } from 'reactstrap';
import { LoadingPlaceholder } from '../../components/shared/LoadingPlaceholder/LoadingPlaceholder';
import { useParams } from 'react-router-dom';

export const BlogSingle = () => {
  const { slug } = useParams<{ slug: string }>();

  const { data, loading, error } = useQuery<BlogPostResponse>(GET_BLOG_POST, {
    variables: {
      slug: slug,
    },
  });

  return (
    <div className='BlogSingle'>
      <HandleLoadingState
        loading={loading}
        loadingPlaceholder={
          <Container>
            <Row>
              <Col xs='12' md='12' className='d-flex justify-content-center mt-5'>
                <LoadingPlaceholder />
              </Col>
            </Row>
          </Container>
        }
      >
        <ApolloErrorGuard error={error}>
          <DefaultPage
            content={data?.postBy?.content}
            title={data?.postBy?.title}
            excerpt={data?.postBy?.excerpt}
            featuredImage={data?.postBy?.featuredImage?.node?.sourceUrl}
          />
        </ApolloErrorGuard>
      </HandleLoadingState>
    </div>
  );
};
