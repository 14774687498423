import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from './useSearchParams';

export const useInitialSearchParams = () => {
  const [params, setParams] = useState<any>(null);
  const searchParams = useSearchParams();

  const loaded: React.MutableRefObject<boolean> = useRef(false);

  useEffect(() => {
    if (!loaded.current) {
      setParams(searchParams);
      loaded.current = true;
    }
  }, []);

  return params;
};
