import React from 'react';
import { FormGroup, Label, Input, FormFeedback, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { CheckoutFormik } from '../../../lib/formik/useCheckoutFormik';
import { fields, formInputs } from '../../../lib/translation/strings';
import { SelectCountry } from '../../shared/SelectCountry/SelectCountry';
import { SelectState } from '../../shared/SelectState/SelectState';

interface Props {
  formik: CheckoutFormik;
}

export const ShippingFields = (props: Props) => {
  const { t } = useTranslation();
  const formik = props.formik;
  return (
    <>
      <Row>
        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Label for='shippingFirstName'>{t(formInputs.name)}</Label>
            <Input
              type='text'
              id='shippingFirstName'
              name='shipping.firstName'
              value={formik.values.shipping.firstName}
              onChange={formik.handleChange}
              className='form-control'
              invalid={!!formik.errors.shipping?.firstName && formik.touched.shipping?.firstName}
            />
            <FormFeedback>{formik.errors.shipping?.firstName}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Label for='shippingLastName'>{t(formInputs.lastName)}</Label>
            <Input
              type='text'
              id='shippingLastName'
              name='shipping.lastName'
              value={formik.values.shipping.lastName}
              onChange={formik.handleChange}
              className='form-control'
              invalid={!!formik.errors.shipping?.lastName && formik.touched.shipping?.lastName}
            />
            <FormFeedback>{formik.errors.shipping?.lastName}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs='12' sm='12'>
          <FormGroup className='form-group'>
            <Label for='shippingAddress'>{t(formInputs.address)}</Label>
            <Input
              type='text'
              id='shippingAddress'
              name='shipping.address1'
              value={formik.values.shipping.address1}
              onChange={formik.handleChange}
              className='form-control'
              invalid={!!formik.errors.shipping?.address1 && formik.touched.shipping?.address1}
            />
            <FormFeedback>{formik.errors.shipping?.address1}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs='12' md='6'>
          <FormGroup className='form-group'>
            <Label for='shippingCity'>{t(fields.city)}</Label>
            <Input
              type='text'
              id='shippingCity'
              name='shipping.city'
              onChange={formik.handleChange}
              className='form-control-custom'
              value={formik.values.shipping.city}
              invalid={!!formik.errors.shipping?.city && formik.touched.shipping?.city}
            />
            <FormFeedback>{formik.errors.shipping?.city}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs='12' md='6'>
          <FormGroup className='form-group'>
            <Label for='shippingPostcode'>{t(fields.zipCode)}</Label>
            <Input
              type='text'
              id='shippingPostcode'
              name='shipping.postcode'
              onChange={formik.handleChange}
              className='form-control-custom'
              value={formik.values.shipping.postcode}
              invalid={!!formik.errors.shipping?.postcode && formik.touched.shipping?.postcode}
            />
            <FormFeedback>{formik.errors.shipping?.postcode}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs='12' md='6'>
          <FormGroup className='form-group'>
            <Label for='shippingState'>{t(fields.state)}</Label>
            <SelectState
              country={formik.values.shipping.country}
              name='shipping.state'
              initialValue={formik.values.shipping.state}
              hasError={!!formik.errors.shipping?.state && formik.touched.shipping?.state}
              errorMessage={formik.errors.shipping?.state}
              onSelect={(state: string) => {
                formik.setFieldValue('shipping', {
                  ...formik.values.shipping,
                  state,
                });
                formik.setFieldTouched('shipping');
              }}
              onComplete={() => {
                return;
              }}
            />
          </FormGroup>
        </Col>
        <Col xs='12' md='6'>
          <FormGroup className='form-group'>
            <Label for='shippingCountry'>{t(fields.country)}</Label>
            <SelectCountry
              name='shipping.country'
              initialValue={formik.values.shipping.country}
              hasError={!!formik.errors.shipping?.country && formik.touched.shipping?.country}
              errorMessage={formik.errors.shipping?.country}
              onSelect={(country: string) => {
                formik.setFieldValue('shipping', {
                  ...formik.values.shipping,
                  country,
                });
                formik.setFieldTouched('shipping');
              }}
              onComplete={() => {
                return;
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};
