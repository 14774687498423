import React, { useState } from 'react';
import { Container } from 'reactstrap';
import { Product } from '../../types/productTypes';
import { ApolloErrorGuard } from '../../components/shared/ApolloErrorGuard/ApolloErrorGuard';
import { HandleLoadingState } from '../../components/shared/HandleLoadingState/HandleLoadingState';
import { HandleNoItemsState } from '../../components/shared/HandleNoItemsState/HandleNoItemsState';

import { useWishlist, WishlistAttributes } from '../../lib/hooks/useWishlist';

import { WishlistLoader } from './WishlistLoader';
import { BulkLoader } from '../../components/shared/BulkLoader/BulkLoader';

import { EmptyPage } from '../../components/shared/EmptyMessage/EmptyPage';
import { ProductItem } from '../../components/ProductsList/ProductItem';
import { HeadingTitle } from '../../components/shared/HeadingTitle/HeadingTitle';
import { HeadingTitleLoader } from '../../components/shared/HeadingTitle/HeadingTitleLoader';

import './Wishlist.scss';
import { logError } from '../../lib/helpers/logError';
import { useTranslation } from 'react-i18next';
import { actions, fields, messages } from '../../lib/translation/strings';

export const Wishlist = () => {
  const { t } = useTranslation();
  const wishlist = useWishlist();

  const [state, setState] = useState<WishlistAttributes>({
    loading: true,
    error: undefined,
    data: [],
  });

  React.useEffect(() => {
    wishlist.getUserWishlist().then((res: WishlistAttributes | undefined) => {
      if (res) {
        setState({ ...res });
      }
    });
  }, []);

  const handleRemove = async (id: number) => {
    try {
      await wishlist.removeItem(id);

      setState((prev) => {
        return {
          ...prev,
          data: state.data?.filter((item: any) => item.databaseId !== id),
        };
      });
    } catch (e) {
      logError(e);
    }
  };

  return (
    <div className='Wishlist'>
      <Container className='p-0-mobile'>
        <ApolloErrorGuard error={state.error}>
          <HandleLoadingState
            loading={state.loading}
            loadingPlaceholder={
              <>
                <HeadingTitleLoader />
                <div className='Wishlist__list mt-5'>
                  <BulkLoader length={8}>
                    <WishlistLoader />
                  </BulkLoader>
                </div>
              </>
            }
          >
            <HandleNoItemsState
              hasItems={state.data.length > 0}
              placeholder={
                <EmptyPage
                  title={t(fields.myList)}
                  buttonText={t(actions.addProducts)}
                  description={t(messages.emptyList)}
                />
              }
            >
              <HeadingTitle icon='heart' title={t(fields.myList)} />

              <div className='Wishlist__list'>
                {state.data.map((item: Product) => (
                  <ProductItem
                    key={item.id}
                    product={item}
                    noWishlistButton={true}
                    onRemove={() => handleRemove(item.databaseId)}
                  />
                ))}
              </div>
            </HandleNoItemsState>
          </HandleLoadingState>
        </ApolloErrorGuard>
      </Container>
    </div>
  );
};
