import gql from 'graphql-tag';

export type ContactFormResponse = {
  message: string;
};

export type ContactFormInput = {
  city: string;
  country: string;
  email: string;
  first_name: string;
  last_name: string;
  message: string;
  phone: string;
  subject: string;
};

export const CONTACT_FORM = gql`
  mutation ContactUs(
    $city: String!
    $country: String!
    $email: String!
    $first_name: String!
    $last_name: String!
    $message: String!
    $phone: String!
    $subject: String!
  ) {
    contactForm(
      input: {
        first_name: $first_name
        last_name: $last_name
        city: $city
        email: $email
        phone: $phone
        country: $country
        subject: $subject
        message: $message
      }
    ) {
      message
    }
  }
`;
