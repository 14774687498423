import React from 'react';
import cn from 'classnames';
import { Icon } from '../../components/shared/Icon/Icon';
import { NavLink, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { FooterOptionsResponse, GET_FOOTER_OPTIONS } from '../../queries/generalQueries';
import { HandleLoadingState } from '../../components/shared/HandleLoadingState/HandleLoadingState';
import { ApolloErrorGuard } from '../../components/shared/ApolloErrorGuard/ApolloErrorGuard';
import { FooterMenuLoader } from './FooterMenuLoader';
import { BulkLoader } from '../../components/shared/BulkLoader/BulkLoader';
import { ReactComponent as LogoIcon } from '../../assets/logo-v.svg';
import { ThemeLink } from '../../components/shared/ThemeLink/ThemeLink';

import './Footer.scss';

export const Footer = () => {
  const { pathname } = useLocation();
  const { data, error, loading } = useQuery<FooterOptionsResponse>(GET_FOOTER_OPTIONS);
  const footer = data?.generalOption.generalOptions.footer;

  const noTopMargin = pathname === '/contact';
  return (
    <div className={cn('Footer', noTopMargin && 'Footer--no-top-margin')}>
      <div className='Footer__top'>
        <ThemeLink to='/'>
          <LogoIcon className='Footer__top__logo' />
        </ThemeLink>
      </div>
      <div className='Footer__menus'>
        <HandleLoadingState
          loadingPlaceholder={
            <BulkLoader length={3}>
              <FooterMenuLoader />
            </BulkLoader>
          }
          loading={loading}
        >
          <ApolloErrorGuard error={error}>
            {footer?.menus.map(({ title, menu }, index) => {
              return (
                <div key={index} className='Footer__menu'>
                  <h4>{title}</h4>
                  <ul>
                    {menu.map(({ name, link }, index) => {
                      return (
                        <li key={index}>
                          {link.startsWith('/') ? (
                            <NavLink to={link}>{name}</NavLink>
                          ) : (
                            <a href={link} target='_blank' rel='noopener noreferrer'>
                              {name}
                            </a>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </ApolloErrorGuard>
        </HandleLoadingState>
        <HandleLoadingState loadingPlaceholder={<FooterMenuLoader />} loading={loading}>
          <ApolloErrorGuard error={error}>
            <div className='Footer__menu'>
              <h4>Contact</h4>
              <ul>
                <li>
                  <NavLink to='/contact'>Contact</NavLink>
                </li>
                {footer?.address && (
                  <li>
                    <p>{footer?.address}</p>
                  </li>
                )}
                {footer?.phone && (
                  <li>
                    <p>{footer?.phone}</p>
                  </li>
                )}
                {footer?.email && (
                  <li>
                    <p>{footer?.email}</p>
                  </li>
                )}
              </ul>
            </div>
          </ApolloErrorGuard>
        </HandleLoadingState>
      </div>
      <div className='Footer__copyright'>
        <span>
          Copyright <Icon icon='copyright' /> <b>ValentineCouture </b> {new Date().getFullYear()}
        </span>
        <ul className='Footer__social-media'>
          {footer?.facebook && (
            <li>
              <a href={footer.facebook} target='_blank' rel='noopener noreferrer'>
                <Icon icon='facebook' />
              </a>
            </li>
          )}
          {footer?.instagram && (
            <li>
              <a href={footer.instagram} target='_blank' rel='noopener noreferrer'>
                <Icon icon='instagram' />
              </a>
            </li>
          )}
          {footer?.youtube && (
            <li>
              <a href={footer.youtube} target='_blank' rel='noopener noreferrer'>
                <Icon icon='youtube' />
              </a>
            </li>
          )}
          {footer?.twitter && (
            <li>
              <a href={footer.twitter} target='_blank' rel='noopener noreferrer'>
                <Icon icon='twitter' />
              </a>
            </li>
          )}
          {footer?.tiktok && (
            <li>
              <a href={footer.tiktok} target='_blank' rel='noopener noreferrer'>
                <Icon icon='tiktok' />
              </a>
            </li>
          )}
          {footer?.pinterest && (
            <li>
              <a href={footer.pinterest} target='_blank' rel='noopener noreferrer'>
                <Icon icon='pinterest' />
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
