import React from 'react';
import { Image } from '../../shared/Image/Image';
import './PageBanner.scss';

interface PageBannerProps {
  imageUrl: string;
  title: string;
}

export const PageBanner = (props: PageBannerProps) => {
  return (
    <div className='PageBanner'>
      <Image dynamicRef={true} src={props.imageUrl} alt={props.title} />
      <div className='PageBanner__content'>
        <h1>{props.title}</h1>
      </div>
    </div>
  );
};
