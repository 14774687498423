import React from 'react';
import { ThemeLink } from '../../../components/shared/ThemeLink/ThemeLink';
import { ReactComponent as LogoIcon } from '../../../assets/logo.svg';

import './Logo.scss';

interface Props {
  noLink?: boolean;
}

export const Logo = (props: Props) => {
  return (
    <div className='Logo'>
      {props.noLink ? (
        <LogoIcon className='Logo__svg' />
      ) : (
        <ThemeLink to='/'>
          <LogoIcon className='Logo__svg' />
        </ThemeLink>
      )}
    </div>
  );
};
