import React from 'react';
import './HomePrimaryCollection.scss';
import { ApolloErrorGuard } from '../../shared/ApolloErrorGuard/ApolloErrorGuard';
import { useQuery } from '@apollo/client';
import { PrimaryCollectionResponse, getPrimaryCollection } from '../../../queries/home';
import { HandleLoadingState } from '../../shared/HandleLoadingState/HandleLoadingState';
import { ThemeLink } from '../../shared/ThemeLink/ThemeLink';
import { Button } from '../../shared/Button/Button';
import { HomePrimaryCollectionLoader } from './HomePrimaryCollectionLoader';

export const HomePrimaryCollection = () => {
  const { loading, error, data } = useQuery<PrimaryCollectionResponse>(getPrimaryCollection);

  const primaryCollection = data?.generalOption.homeSettings.primaryCollection;

  return (
    <HandleLoadingState loading={loading} loadingPlaceholder={<HomePrimaryCollectionLoader />}>
      <ApolloErrorGuard error={error}>
        <div className='HomePrimaryCollection'>
          <div className='HomePrimaryCollection__header'>
            <h5>{primaryCollection?.subTitle}</h5>
            <h3>{primaryCollection?.title}</h3>
            <p>{primaryCollection?.description}</p>
          </div>
          <div className='HomePrimaryCollection__products'>
            {primaryCollection?.products?.nodes?.map((product) => {
              return (
                <ThemeLink
                  key={product.id}
                  to={`/product/${product.slug}`}
                  className='HomePrimaryCollection__product'
                >
                  <div className='HomePrimaryCollection__product-img'>
                    <img src={product.image.sourceUrl} alt={product.name} />
                  </div>
                  <h6>{product.name}</h6>
                </ThemeLink>
              );
            })}
          </div>
          <div className='HomePrimaryCollection__footer'>
            <Button to={primaryCollection?.button.url}>{primaryCollection?.button.title}</Button>
          </div>
        </div>
      </ApolloErrorGuard>
    </HandleLoadingState>
  );
};
