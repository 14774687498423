import React from 'react';
import { ShopSelectOptions } from './ShopSelect';

import './ShopSelect.scss';

interface Props {
  selected?: ShopSelectOptions[];
  options?: ShopSelectOptions[];
  handleOnItemSelect: (option: ShopSelectOptions) => void;
}

export const ShopSelectOptionsColor = (props: Props) => {
  return (
    <div className='ShopSelect__options_color'>
      {props.options?.map((option, index) => {
        const items = props.selected;

        const target =
          items && Array.isArray(items)
            ? items.find((o) => {
                return o.value === option.value;
              })
            : null;

        return (
          <div
            key={`${option.value}-${index}`}
            data-selected={target?.value === option.value}
            onClick={() => props.handleOnItemSelect(option)}
          >
            {option.label}
          </div>
        );
      })}
    </div>
  );
};
