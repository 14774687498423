import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { ProductSingleSliderItem } from './ProductSingleSliderItem/ProductSingleSliderItem';
import { useTranslation } from 'react-i18next';
import { Image } from '../../shared/Image/Image';
import { texts } from '../../../lib/translation/strings';
// import { useWindowWidth } from '../../../lib/hooks/useWindowWidth';
import PrismaZoom from 'react-prismazoom';

import './ProductSingleSlider.scss';
import { Icon } from '../../shared/Icon/Icon';

interface Props {
  selectedVariationSlug?: string;
  featureImage: string;
  alt: string;
  gallery?: any;
  isOutOfStock?: boolean;
}

export const ProductSingleSlider = (props: Props) => {
  // const windowWidth = useWindowWidth();
  // const isMobile = windowWidth < 768;
  const { t } = useTranslation();
  const [slider1, setSlider1] = useState<any>();
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  // const [slider2, setSlider2] = useState<any>();
  const [isImageModalOpen, setIsImageModalOpen] = useState<number | undefined>();

  useEffect(() => {
    if (props.selectedVariationSlug === undefined) {
      return;
    }

    const img = props.gallery?.find((item: any) => item.slug === props.selectedVariationSlug);

    if (!img) {
      return;
    }

    const imgIndex = props.gallery?.indexOf(img);

    if (imgIndex === undefined) {
      return;
    }

    slider1?.slickGoTo(imgIndex);
    // slider2?.slickGoTo(imgIndex);
  }, [props.selectedVariationSlug, props.gallery, slider1]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsImageModalOpen(undefined);
      } else if (e.key === 'ArrowRight') {
        e.preventDefault();
        slider1?.slickNext();
      } else if (e.key === 'ArrowLeft') {
        e.preventDefault();
        slider1?.slickPrev();
      }
    };

    if (isImageModalOpen === undefined) {
      window.removeEventListener('keydown', handleKeyPress);
      return;
    }
    window.addEventListener('keydown', handleKeyPress);
    // return () => {
    //   window.removeEventListener('keydown', handleKeyPress);
    // };
  }, [isImageModalOpen]);

  const sliderImages = props.gallery || [];
  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
    afterChange: (currentSlide: number) => {
      setCurrentSlide(currentSlide);
    },
    // asNavFor: slider2,
  };

  // const settingsThumbs = {
  //   variableWidth: isMobile,
  //   slidesToShow: isMobile ? 4 : Math.min(sliderImages.length, 3),
  //   slidesToScroll: 1,
  //   asNavFor: slider1,
  //   vertical: !isMobile,
  //   verticalSwiping: !isMobile,
  //   dots: false,
  //   centerMode: false,
  //   infinite: false,
  //   arrows: false,
  //   swipeToSlide: true,
  //   focusOnSelect: true,
  // };

  const openImageModal = (index: number) => {
    setIsImageModalOpen(index);
  };

  return (
    <div className='ProductSingleSlider'>
      {props.isOutOfStock && (
        <div className='ProductSingleSlider--nostock'>
          <span>{t(texts.sold)}</span>
        </div>
      )}

      {sliderImages.length > 0 ? (
        <>
          <Slider
            {...settingsMain}
            ref={(slider) => setSlider1(slider)}
            className='ProductSingleSlider__gallery'
          >
            {sliderImages.map((image: { sourceUrl: string }, index: number) => {
              return (
                <ProductSingleSliderItem
                  image={image.sourceUrl}
                  key={`${image.sourceUrl}-${index}`}
                  toggleImageModal={() => openImageModal(index)}
                />
              );
            })}
          </Slider>
          <div className='ProductSingleSlider__counter'>
            <p>
              {currentSlide + 1} / {slider1?.innerSlider?.state?.slideCount}
            </p>
          </div>
        </>
      ) : (
        <Image />
      )}

      {/*Thumb slider*/}
      {/* {sliderImages.length > 0 && (
        <Slider
          {...settingsThumbs}
          className='ProductSingleSlider__thumbs'
          ref={(slider) => setSlider2(slider)}
        >
          {sliderImages.map((image: { sourceUrl: string }, index: number) => {
            return (
              <div
                key={`${image.sourceUrl}-${index}`}
                className='ProductSingleSlider__thumbs__item'
              >
                <Image src={image.sourceUrl} alt={props.alt} />
              </div>
            );
          })}
        </Slider>
      )} */}
      {isImageModalOpen !== undefined && (
        <div className='GallerySlider'>
          <span className='GallerySlider__close' onClick={() => setIsImageModalOpen(undefined)} />
          <span
            role='button'
            className='GallerySlider__close-btn'
            onClick={() => setIsImageModalOpen(undefined)}
          >
            <Icon icon='x' />
          </span>
          <div className='GallerySlider__image'>
            <PrismaZoom>
              <img
                src={
                  sliderImages[slider1.innerSlider.state.currentSlide ?? isImageModalOpen ?? 0]
                    .sourceUrl
                }
              />
            </PrismaZoom>
          </div>
        </div>
      )}
    </div>
  );
};
