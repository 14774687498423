import React, { useEffect, useState } from 'react';
import { MenuSidebar, MenuSidebarType } from './MenuSidebarContext';
import { useRouter } from '../../hooks/useRouter';

interface Props {
  children: React.ReactNode;
}

type AvaliableSidebars = 'cart' | 'wishlist';

interface AvailableSidebarsType {
  [key: string]: boolean;
}

export const MenuSidebarContextProvider = (props: Props) => {
  const router = useRouter();
  const [sidebars, setSidebars] = useState<AvailableSidebarsType>({
    cart: false,
    wishlist: false
  });

  useEffect(() => {
    setSidebars({
      cart: false,
      wishlist: false
    });
  }, [router.location.pathname]);

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (sidebars.cart || sidebars.wishlist) {
      document.body.style.overflow = 'hidden';
      document.getElementById('App')?.classList.add('App--show-backdrop');
    }
    return () => {
      document.body.style.overflow = originalStyle;
      document.getElementById('App')?.classList.remove('App--show-backdrop');
    };
  }, [sidebars]);

  const openSidebar = (name: AvaliableSidebars) => {
    setSidebars({
      [name]: true
    });
  };

  const closeSidebar = (name: AvaliableSidebars) => {
    setSidebars({
      [name]: false
    });
  };

  const toggleSidebar = (name: AvaliableSidebars) => {
    setSidebars({
      [name]: !sidebars[name]
    });
  };

  const context: MenuSidebarType = {
    cart: sidebars.cart,
    wishtlist: sidebars.wishlist,
    closeSidebar,
    openSidebar,
    toggleSidebar
  };

  return (
    <MenuSidebar.Provider value={context}>
      {props.children}
    </MenuSidebar.Provider>
  );
};
