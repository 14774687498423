import gql from 'graphql-tag';
import { Product, ProductVariation } from '../types/productTypes';

export interface OrderItem {
  orderId: number;
  total: string;
  quantity: number;
  product: Product;
  variation: ProductVariation;
}

export interface Order {
  order: {
    id: string;
    databaseId: number;
    status: string;
    subtotal: string;
    total: string;
    shippingTotal: string;
    date: string;
    dateCompleted: string;
    orderNumber: string;
    currency: string;
    paymentMethod: string;
    paymentMethodTitle: string;
    billing: {
      address1: string;
      firstName: string;
      lastName: string;
      country: string;
      postcode: string;
      phone: string;
      email: string;
      state: string;
    };
    shipping: {
      address1: string;
      city: string;
      email: string;
      firstName: string;
      lastName: string;
      phone: string;
      postcode: string;
      state: string;
    };
    shippingLines: {
      nodes: { methodTitle?: string }[];
    };
    lineItems: {
      nodes: OrderItem[];
    };
  };
}

export interface OrdersItem {
  id: string;
  orderNumber: string;
  total: string;
  currency: string;
  status: string;
  date: string;
  lineItems: {
    nodes: {
      product: {
        node: {
          id: string;
          image: {
            id: string;
            sourceUrl: string;
          };
        };
      };
    }[];
  };
}

export interface Orders {
  customer: {
    orders: {
      nodes: OrdersItem[];
    };
  };
}

export const GET_ORDERS_QUERY = gql`
  query getOrdersQuery {
    customer {
      orders(first: 1000) {
        nodes {
          id
          orderNumber
          total(format: FORMATTED)
          currency
          status
          date
          lineItems {
            nodes {
              product {
                node {
                  id
                  image {
                    id
                    sourceUrl(size: MEDIUM)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export interface GetOrderByIdVariables {
  id: string;
}

export interface OrderItemProduct {
  node: {
    id: string;
    slug: string;
    databaseId: number;
    name: string;
    onSale: boolean;
    type: string;
    image: {
      id: string;
      sourceUrl: string;
    };
    salePrice: string | null;
    regularPrice: string;
  };
}

export interface OrderItemVariation {
  node: {
    id: string;
    sku: string;
    onSale: boolean;
    salePrice: string | null;
    regularPrice: string;
    stockQuantity: number;
    attributes: {
      nodes: {
        id: string;
        name: string;
        value: string;
      }[];
    };
  };
}
export interface OrderItemByID {
  orderId: number;
  total: string;
  quantity: number;
  product: OrderItemProduct;
  variation: OrderItemVariation;
}
export interface GetOrderByIdResponse {
  order: {
    id: string;
    databaseId: number;
    status: string;
    subtotal: string;
    total: string;
    shippingTotal: string;
    date: string;
    dateCompleted: string;
    orderNumber: string;
    currency: string;
    paymentMethod: string;
    paymentMethodTitle: string;
    billing: {
      address1: string;
      firstName: string;
      lastName: string;
      country: string;
      postcode: string;
      phone: string;
      email: string;
      state: string;
    };
    shipping: {
      address1: string;
      city: string;
      email: string;
      firstName: string;
      lastName: string;
      phone: string;
      postcode: string;
      state: string;
    };
    shippingLines: {
      nodes: { methodTitle?: string }[];
    };
    lineItems: {
      nodes: OrderItemByID[];
    };
  };
}

export const getOrderById = gql`
  query getOrderById($id: ID) {
    order(id: $id) {
      id
      databaseId
      status
      subtotal
      total
      shippingTotal
      date
      dateCompleted
      orderNumber
      currency
      paymentMethod
      paymentMethodTitle
      billing {
        address1
        firstName
        lastName
        country
        postcode
        phone
        email
        state
      }

      shipping {
        address1
        city
        email
        firstName
        lastName
        phone
        postcode
        state
      }

      shippingLines {
        nodes {
          methodTitle
        }
      }

      lineItems {
        nodes {
          orderId
          quantity
          total
          variation {
            node {
              id
              sku
              onSale
              salePrice
              regularPrice
              stockQuantity
              attributes {
                nodes {
                  id
                  name
                  value
                }
              }
            }
          }
          product {
            node {
              id
              slug
              databaseId
              name
              onSale
              type
              image {
                id
                sourceUrl(size: MEDIUM_LARGE)
              }
              ... on SimpleProduct {
                id
                name
                salePrice
                regularPrice
              }
              ... on VariableProduct {
                id
                name
                salePrice
                regularPrice
              }
            }
          }
        }
      }
    }
  }
`;
