import React from 'react';
import './LoadingPlaceholder.scss';
import { Icon } from '../Icon/Icon';

type Props = { text?: string; loadingPlaceholder?: React.ReactNode };

export const LoadingPlaceholder = ({ text, loadingPlaceholder }: Props) => {
  return (
    <div className='LoadingPlaceholder'>
      {loadingPlaceholder || (
        <>
          <Icon icon='loader' spin /> <p>{text || 'Loading ...'}</p>
        </>
      )}
    </div>
  );
};
