import React from 'react';
import ContentLoader from 'react-content-loader';
import { BulkLoader } from '../../components/shared/BulkLoader/BulkLoader';

export const FooterMenuLoader = () => {
  return (
    <div className='Footer__menu'>
      <h4>
        <ContentLoader
          speed={2}
          width={150}
          height={20}
          viewBox='0 0 150 20'
          backgroundColor='#282828'
          foregroundColor='#ecebeb'
        >
          <rect x='0' y='0' rx='0' ry='0' width='150' height='20' />
        </ContentLoader>
      </h4>
      <ul>
        <BulkLoader length={4}>
          <li>
            <ContentLoader
              speed={2}
              width={250}
              height={20}
              viewBox='0 0 250 20'
              backgroundColor='#282828'
              foregroundColor='#ecebeb'
            >
              <rect x='0' y='0' rx='0' ry='0' width='250' height='20' />
            </ContentLoader>
          </li>
        </BulkLoader>
      </ul>
    </div>
  );
};
