import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormFeedback, FormGroup, Input, Label, Spinner } from 'reactstrap';

import { useCartContext } from '../../../lib/context/CartContext/CartContext';

import { actions, errors, fields } from '../../../lib/translation/strings';

import './ApplyCoupon.scss';

export const ApplyCoupon = () => {
  const cartCtx = useCartContext();
  const { t } = useTranslation();

  const [error, setError] = useState('');
  const [coupon, setCoupon] = useState('');

  useEffect(() => {
    setError('');
    setCoupon('');
  }, []);

  const handleApply = () => {
    setError('');
    if (!coupon) {
      setError(t(errors.requiredCoupon));
      return;
    }
    cartCtx
      .applyCoupon(coupon)
      .then(() => {
        setCoupon('');
        setError('');
      })
      .catch((e) => {
        setError((e as Error).message);
      });
  };

  return (
    <>
      {cartCtx.hasCoupons ? (
        <div className='OrderDetails mt-4'>
          <div className='OrderDetails__table'>
            <h4 className='OrderDetails__table__title'>{t(fields.usedCoupons)}</h4>
            <div className='OrderDetails__table__thead'>
              <strong className='half'>{t(fields.cuponCode)}</strong>
              <strong className='pl-0 text-center'>{t(fields.price)}</strong>
              <strong className='text-right'>{t(fields.action)}</strong>
            </div>

            {cartCtx.appliedCoupons && (
              <div className='OrderDetails__table__item' key={cartCtx.appliedCoupons.code}>
                <span className='half'>{cartCtx.appliedCoupons.code}</span>
                <span className='pl-0 text-center'>
                  {cartCtx.appliedCoupons.discountAmount}{' '}
                  {cartCtx.appliedCoupons.discountTax === 'PERCENT' ? '%' : '€'}
                </span>
                <span className='pl-0 text-right'>
                  <Button className='OrderDetails__table__item__btn' onClick={() => cartCtx.removeCoupon(`${cartCtx.appliedCoupons?.code}`)}>
                    {t(actions.remove)}
                  </Button>
                </span>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className='ApplyCoupon'>
          <div className='ApplyCoupon__form'>
            <FormGroup className='mb-0 w-100'>
              <Label for='coupon'>{t(fields.cuponCode)}</Label>
              <Input
                id='coupon'
                name='coupon'
                className='ApplyCoupon__input'
                invalid={!!error}
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
              />
              <FormFeedback>{error}</FormFeedback>
            </FormGroup>
            <Button
              className='ApplyCoupon__btn'
              onClick={handleApply}
              disabled={cartCtx.isApplyingCoupon}
            >
              {cartCtx.isApplyingCoupon ? <Spinner size='sm' /> : t(actions.apply)}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
