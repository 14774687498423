import gql from 'graphql-tag';

export const LOGIN_MUTATION = gql`
  mutation LoginUser($username: String!, $password: String!, $wishlist: [Int]) {
    login(
      input: {
        clientMutationId: "login-mutation"
        username: $username
        password: $password
        wishlist: $wishlist
      }
    ) {
      authToken
      refreshToken
      user {
        id
        name
        email
        userId
        lastName
        username
        firstName
        wishListProducts {
          wishlistProducts
        }
      }
    }
  }
`;

export interface RegisterProps {
  firstname: string;
  lastname: string;
  municipality: string;
  address: string;
  password: string;
  telephone: string;
  email: string;
}

export interface RegisterResults {
  registerCustomer: {
    clientMutationId: string;
    authToken: string;
    refreshToken: string;
    customer: {
      id: string;
      displayName: string;
      email: string;
      databaseId: number;
      lastName: string;
      username: string;
      firstName: string;
    };
  };
}

export const REGISTER_MUTATION = gql`
  mutation register(
    $firstname: String
    $lastname: String
    $municipality: String
    $address: String
    $password: String
    $telephone: String
    $email: String!
  ) {
    registerCustomer(
      input: {
        clientMutationId: "register-mutation"
        username: $email
        firstName: $firstname
        lastName: $lastname
        email: $email
        shippingSameAsBilling: true
        password: $password
        billing: {
          firstName: $firstname
          lastName: $lastname
          country: XK
          address1: $address
          city: $municipality
          phone: $telephone
        }
      }
    ) {
      authToken
      refreshToken
      clientMutationId
      customer {
        id
        displayName
        email
        databaseId
        lastName
        username
        firstName
      }
    }
  }
`;

export interface ForgotPasswordProps {
  username: string;
}

export interface ForgotPasswordResults {
  clientMutationId: string;
}

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($username: String!) {
    sendPasswordResetEmailvalentine(
      input: { clientMutationId: "forgot-password", username: $username }
    ) {
      clientMutationId
    }
  }
`;

export interface ResetPasswordProps {
  key: string;
  login: string;
  password: string;
}

export interface ResetPasswordResults {
  clientMutationId: string;
}

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($key: String!, $login: String!, $password: String!) {
    resetUserPassword(
      input: { clientMutationId: "reset-password", key: $key, login: $login, password: $password }
    ) {
      clientMutationId
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateProfileMutation($id: ID, $firstName: String, $lastName: String) {
    updateCustomer(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        clientMutationId: "update-customer-profile"
      }
    ) {
      customer {
        firstName
        lastName
      }
    }
  }
`;

export const UPDATE_ADDRESS_MUTATION = gql`
  mutation updateAddressMutation(
    $id: ID
    $billingFirstName: String
    $billingLastName: String
    $billingCity: String
    $billingAddress: String
    $billingPhone: String
    $billingPostcode: String
    $billingState: String
    $billingCountry: CountriesEnum
    $shippingFirstName: String
    $shippingLastName: String
    $shippingCity: String
    $shippingAddress: String
    $shippingPostcode: String
    $shippingState: String
    $shippingCountry: CountriesEnum
  ) {
    updateCustomer(
      input: {
        clientMutationId: "customer-update-mutation"
        id: $id
        billing: {
          firstName: $billingFirstName
          lastName: $billingLastName
          city: $billingCity
          address1: $billingAddress
          phone: $billingPhone
          postcode: $billingPostcode
          state: $billingState
          country: $billingCountry
        }
        shipping: {
          firstName: $shippingFirstName
          lastName: $shippingLastName
          city: $shippingCity
          address1: $shippingAddress
          postcode: $shippingPostcode
          state: $shippingState
          country: $shippingCountry
        }
      }
    ) {
      customer {
        billing {
          address1
          city
          firstName
          lastName
          phone
          postcode
          state
          country
        }
        shipping {
          address1
          city
          firstName
          lastName
          phone
          postcode
          state
          country
        }
      }
    }
  }
`;

export const UPDATE_PROFILE_PASSWORD_MUTATION = gql`
  mutation updateProfilePasswordMutation($id: ID!, $password: String) {
    updateUser(
      input: { id: $id, password: $password, clientMutationId: "update-profile-password-mutation" }
    ) {
      clientMutationId
    }
  }
`;

export const REMOVE_ITEM_FROM_WISHLIST = gql`
  mutation removeItemFromWishlist($databaseId: Int, $userId: Int) {
    removeFromWishlist(input: { id: $databaseId, userID: $userId }) {
      message
    }
  }
`;

export const ADD_ITEM_TO_WISHLIST = gql`
  mutation addItemFromWishlist($ids: [Int], $userId: Int) {
    addToWishlist(input: { ids: $ids, userID: $userId }) {
      message
    }
  }
`;
