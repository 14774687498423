import React from 'react';
import { Input } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import {
  SHIPPING_METHODS,
  ShippingMethodsResults,
  ShippingMethod as ShippingMethodProps,
} from '../../../queries/checkoutQueries';
import { fields } from '../../../lib/translation/strings';
import { BulkLoader } from '../../shared/BulkLoader/BulkLoader';
import { CheckoutFormik } from '../../../lib/formik/useCheckoutFormik';
import { ApolloErrorGuard } from '../../shared/ApolloErrorGuard/ApolloErrorGuard';
import { ProductSingleLoader } from '../../../pages/ProductSingle/ProductSingleLoader';
import { HandleLoadingState } from '../../shared/HandleLoadingState/HandleLoadingState';
import { useCartContext } from '../../../lib/context/CartContext/CartContext';

import './ShippingMethod.scss';

interface Props {
  formik: CheckoutFormik;
}

export const ShippingMethod = (props: Props) => {
  const { t } = useTranslation();
  const cartCtx = useCartContext();

  const shippingMethods = useQuery<ShippingMethodsResults>(SHIPPING_METHODS);

  const handleShippingChange = (shippingMethod: ShippingMethodProps) => {
    cartCtx.updateShippingMethod([shippingMethod.id]);
    props.formik.setFieldValue('shippingMethod', {
      id: shippingMethod.id,
      label: shippingMethod.label,
      cost: shippingMethod.cost,
    });
    props.formik.setFieldTouched('shippingMethod');
  };
  return (
    <div className='ShippingMethod'>
      <HandleLoadingState
        loading={shippingMethods.loading}
        loadingPlaceholder={
          <BulkLoader length={1}>
            <ProductSingleLoader />
          </BulkLoader>
        }
      >
        <ApolloErrorGuard error={shippingMethods.error}>
          <h4 className='ShippingMethod__title'>{t(fields.shippingMethod)}</h4>
          {shippingMethods.data?.cart.availableShippingMethods[0].rates?.map(
            (shippingMethod: ShippingMethodProps) => {
              return (
                <div className='ShippingMethod__item' key={shippingMethod.id}>
                  <label htmlFor={`pm-${shippingMethod.id}`}>
                    <Input
                      type='radio'
                      id={`pm-${shippingMethod.id}`}
                      name='shippingMethod'
                      checked={cartCtx.chosenShippingMethod === shippingMethod.id}
                      value={shippingMethod.id}
                      hidden
                      onChange={() => handleShippingChange(shippingMethod)}
                    />
                    <span className='name'>{shippingMethod.label}</span>
                    <span className='price'>{shippingMethod.cost || '0.00'} €</span>
                  </label>
                </div>
              );
            },
          )}
        </ApolloErrorGuard>
      </HandleLoadingState>
    </div>
  );
};
