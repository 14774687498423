import { ApolloError, useApolloClient } from '@apollo/client';

import { useAuthContext } from '../context/AuthContext/AuthContext';

import { GET_USER_WISHLIST, ProductArchiveItem } from '../../queries/archive';
import { getLocalValues, setLocalValue } from '../helpers/localStorageHelpers';
import {
  ADD_ITEM_TO_WISHLIST,
  REMOVE_ITEM_FROM_WISHLIST,
} from '../../queries/mutations/userMutations';
import { logError } from '../helpers/logError';
import { ApolloErrorOptions } from '@apollo/client/errors';

export interface WishlistAttributes {
  data: any;
  loading?: boolean;
  error?: ApolloError | ApolloErrorOptions;
}

export const useWishlist = () => {
  const authCtx = useAuthContext();
  const client = useApolloClient();

  const getProductsIDs = () => {
    const localValues = authCtx.isAuthenticated
      ? authCtx.user?.wishlist
      : getLocalValues().wishlist;

    if (!localValues) {
      return [];
    }

    return localValues;
  };

  const isProductOnWishList = (id: number) => {
    const productIDs = getProductsIDs();

    if (!productIDs) {
      return false;
    }

    return productIDs.includes(id);
  };

  const getUserWishlist = async () => {
    const products = getProductsIDs();

    if (products.length === 0) {
      return {
        loading: false,
        error: undefined,
        data: [],
      };
    }

    try {
      const res = await client.query({
        query: GET_USER_WISHLIST,
        variables: {
          products: products,
        },
      });

      return {
        loading: res.loading,
        error: res.error,
        data: res.data?.products?.nodes ?? [],
      };
    } catch (e) {
      logError(e);
    }
  };

  const addToWishlist = async (id: number) => {
    const currentValues = getProductsIDs();

    if (isProductOnWishList(id)) {
      return;
    }

    if (!authCtx.isAuthenticated) {
      const values = currentValues.length !== 0 ? [...currentValues, id] : [id];

      setLocalValue('wishlist', values);
      return;
    }

    if (!authCtx.user) {
      return;
    }

    try {
      // TODO: How to handle when mutation fails
      await client.mutate({
        mutation: ADD_ITEM_TO_WISHLIST,
        variables: {
          ids: [id],
          userId: authCtx.user.userId,
        },
      });

      authCtx.updateUser({
        ...authCtx.user,
        wishlist: authCtx.user.wishlist ? [...authCtx.user.wishlist, id] : [id],
      });
    } catch (e) {
      logError(e);
    }
  };

  const filterWishlistItems = (
    cache: ProductArchiveItem[],
    id: number,
    productIDs: number[] = getProductsIDs(),
  ) => {
    return {
      cache: cache ? cache.filter((item) => item.databaseId !== id) : [],
      productIDs: productIDs ? productIDs.filter((item: number) => item !== id) : [],
    };
  };

  const removeItemFromCache = (productsIDs: number[], id: number) => {
    const cachedQuery = client.readQuery({
      query: GET_USER_WISHLIST,
      variables: {
        products: productsIDs,
      },
    });

    const newValues = filterWishlistItems(cachedQuery.products.nodes, id, productsIDs);

    client.writeQuery({
      query: GET_USER_WISHLIST,
      data: {
        products: {
          __typename: cachedQuery.products.__typename,
          nodes: newValues.cache,
        },
      },
      variables: {
        products: newValues.productIDs,
      },
    });

    return newValues.productIDs;
  };

  const removeItemMutation = async (id: number) => {
    const productIDs = getProductsIDs();

    if (!authCtx.user) {
      return;
    }

    try {
      // TODO: How to handle when mutation fails
      await client.mutate({
        mutation: REMOVE_ITEM_FROM_WISHLIST,
        variables: {
          databaseId: id,
          userId: authCtx.user.userId,
        },
      });

      const newIDs = removeItemFromCache(productIDs, id);

      authCtx.updateUser({
        ...authCtx.user,
        wishlist: newIDs,
      });
    } catch (e) {
      logError(e);
    }
  };

  const removeItemLocal = (id: number) => {
    const productIDs = getProductsIDs();

    if (productIDs.length === 0 || !isProductOnWishList(id)) {
      return;
    }

    const newIDs = removeItemFromCache(productIDs, id);

    setLocalValue('wishlist', newIDs);
  };

  const removeItem = async (id: number) => {
    if (!authCtx.isAuthenticated) {
      removeItemLocal(id);
      return;
    }

    try {
      await removeItemMutation(id);
    } catch (error) {
      logError(error);
    }
  };

  return {
    removeItem,
    addToWishlist,
    getProductsIDs,
    getUserWishlist,
    isProductOnWishList,
  };
};
