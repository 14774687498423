import React, { useContext } from 'react';

export interface ShopContextData {
  category?: string;
  subCategory?: string;
  shopType?: 'category';
  shopLinkPrefix?: string;
  order?: string;
  slug?: string;
}

export interface ShopContextHandlers {
  handleCategoryChange?: (category: string) => void;
  handleAttributeChange?: (data: any) => void;
  handleOrderChange?: (value: string) => void;
}

export interface ShopContextType {
  data: ShopContextData;
  handlers?: ShopContextHandlers;
  sidebar: {
    isOpened: boolean;
    toggleSidebar: () => void;
    openSidebar: () => void;
    closeSidebar: () => void;
  };
}

const defaultShopContext: ShopContextType = {
  data: {
    category: undefined,
    subCategory: undefined,
    shopType: undefined,
    shopLinkPrefix: undefined,
    order: undefined,
    slug: undefined,
  },
  sidebar: {
    isOpened: false,
    toggleSidebar: () => {},
    openSidebar: () => {},
    closeSidebar: () => {},
  },
};

export const ShopContext = React.createContext<ShopContextType>(defaultShopContext);

export function useShopContext() {
  return useContext(ShopContext);
}
