import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { Icon } from '../../shared/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { fields, texts } from '../../../lib/translation/strings';
import { useOutsideClickDetection } from '../../../lib/hooks/useOutsideClickDetection';
import { PRODUCT_MODAL } from '../../../pages/ProductSingle/ProductSingle';

import './ProductSingleModal.scss';

type Props = {
  type?: PRODUCT_MODAL;
  description?: string;
  close: () => void;
};

export const ProductSingleModal = ({ type, description, close }: Props) => {
  const opened = !!type;
  const ref = useRef(null);
  const { t } = useTranslation();

  useOutsideClickDetection(ref, () => close());

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (opened) {
      document.body.style.overflow = 'hidden';
      document.getElementById('App')?.classList.add('App--show-backdrop');
    }
    return () => {
      document.body.style.overflow = originalStyle;
      document.getElementById('App')?.classList.remove('App--show-backdrop');
    };
  }, [opened]);

  const getTitleByType = () => {
    switch (type) {
      case PRODUCT_MODAL.DESCRIPTION:
        return fields.description;
      case PRODUCT_MODAL.DELIVERY:
        return texts.deliveryReturns;
      case PRODUCT_MODAL.SIZES_GUIDE:
        return texts.sizeGuide;
      default:
        return '';
    }
  };

  return (
    <div ref={ref} className={cn('ProductSingleModal', opened && 'ProductSingleModal--opened')}>
      <div className='ProductSingleModal-head'>
        <p>{t(getTitleByType())}</p>
        <span role='button' onClick={() => close()}>
          <Icon icon='x' />
        </span>
      </div>
      <div className='ProductSingleModal-body'>
        <div
          className='DefaultPage__content ProductSingle__content__description mt-0'
          dangerouslySetInnerHTML={{
            __html: description || '',
          }}
        />
      </div>
    </div>
  );
};
