import React from 'react';
import { Container } from 'reactstrap';
import { PageBanner } from '../../components/shared/PageBanner/PageBanner';
import image from '../../assets/images/order-confirmation.jpg';

import './CheckoutConfirmation.scss';

export const CheckoutFail = () => {
  return (
    <div className='CheckoutConfirmation'>
      <PageBanner imageUrl={image} title='Order Failed' />

      <Container>
        <div className='CheckoutConfirmation__head'>
          <h1>Oops, something went wrong!</h1>
          <p>
            Looks like there has been a problem with checkout, please try again or contact support.
          </p>
        </div>
      </Container>
    </div>
  );
};
