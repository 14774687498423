import React from 'react';
import ContentLoader from 'react-content-loader';

import './ProductSingleSlider.scss';

export const ProductSingleSliderLoader = () => {
    return (
        <div className="ProductSingleSliderLoader">
            <ContentLoader
                speed={2}
                width={630}
                height={441}
                viewBox="0 0 630 441"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <rect x="0" y="0" rx="0" ry="0" width="630" height="441"/>
            </ContentLoader>
        </div>
    );
};
