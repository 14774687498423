import gql from 'graphql-tag';
import {
  AttributeFilter,
  ChosenAttributesInput,
  PageInfo,
  ProductTaxonomyFilterInput,
} from '../types/generalTypes';
import { Product } from '../types/productTypes';

export interface GetProductsVariables {
  limit: number;
  before?: string;
  after?: string;
  category?: string;
  field?: string;
  order?: string;
  taxonomyFilter?: ProductTaxonomyFilterInput[];
  onSale?: boolean;
  minPrice?: number;
  maxPrice?: number;
}

export interface GetProductsResponse {
  products: {
    nodes: Product[];
    pageInfo?: PageInfo;
  };
}

export const getProducts = gql`
  query getProducts(
    $last: Int
    $limit: Int
    $after: String
    $before: String
    $category: String
    $field: ProductsOrderByEnum!
    $order: OrderEnum
    $taxonomyFilter: [ProductTaxonomyFilterInput]
    $onSale: Boolean
    $maxPrice: Float
    $minPrice: Float
  ) {
    products(
      last: $last
      first: $limit
      after: $after
      before: $before
      where: {
        category: $category
        orderby: { field: $field, order: $order }
        taxonomyFilter: { relation: AND, filters: $taxonomyFilter }
        onSale: $onSale
        maxPrice: $maxPrice
        minPrice: $minPrice
      }
    ) {
      nodes {
        id
        slug
        databaseId
        name
        ... on VariableProduct {
          id
          name
          salePrice(format: FORMATTED)
          regularPrice(format: FORMATTED)
        }
        ... on SimpleProduct {
          id
          name
          salePrice
          regularPrice
        }
        image {
          id
          mediaItemUrl
          sourceUrl(size: MEDIUM_LARGE)
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export interface ShopProductCategory {
  id: string;
  link: string;
  slug: string;
  name: string;
  children: {
    nodes: ShopProductCategory[];
  };
}

export interface GetShopProductCategoriesVariables {
  customTaxonomy?: ChosenAttributesInput[];
}

export interface GetShopProductCategoriesResponse {
  productCategories: {
    nodes: ShopProductCategory[];
  };
}

export const getProductCategories = gql`
  query getProductCategories($customTaxonomy: [ChosenAttributesInput]) {
    productCategories(
      first: 100
      where: { hideEmpty: true, exclude: "15", customTaxonomy: $customTaxonomy }
    ) {
      nodes {
        id
        link
        name
        slug
        children(where: { hideEmpty: true, customTaxonomy: $customTaxonomy }) {
          nodes {
            id
            name
            link
            slug
          }
        }
      }
    }
  }
`;

export interface GetAttributeFiltersVariables {
  chosenAttributes: ChosenAttributesInput[];
}

export interface GetAttributeFiltersResponse {
  attributeFilters: AttributeFilter[];
}

export const getAttributeFilters = gql`
  query getAttributeFilters($chosenAttributes: [ChosenAttributesInput]) {
    attributeFilters(chosenAttributes: $chosenAttributes) {
      id
      name
      label
      slug
      terms {
        id
        count
        name
        slug
        image
      }
    }
  }
`;

export type GetProductBySlugVariables = {
  slug: string;
};

export type GetProductBySlugResponse = {
  terms: {
    nodes: {
      id: string;
      name: string;
      slug: string;
      description: string;
      image?: {
        sourceUrl?: string;
      };
      parent: {
        node: {
          id: string;
          name: string;
        };
      };
    }[];
  };
};

export const GET_COLLECTION_BY_SLUG = gql`
  query GetCollectionBySlug($slug: [String]) {
    terms(where: { slug: $slug }) {
      nodes {
        ... on ProductCategory {
          id
          name
          slug
          description
          image {
            sourceUrl(size: LARGE)
          }
          parent {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
`;
