import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Form, FormGroup, FormFeedback } from 'reactstrap';

import { actions, errors, formInputs } from '../../lib/translation/strings';

import { useAuthContext } from '../../lib/context/AuthContext/AuthContext';
import { useNotification } from '../../lib/context/NotificationContext/NotificationContext';

import { LoginFormik } from '../../lib/formik/useLoginFormik';
import { ThemeLink } from '../../components/shared/ThemeLink/ThemeLink';
import { Button } from '../../components/shared/Button/Button';

import './Login.scss';

interface Props {
  isLoading: boolean;
  formik: LoginFormik;
}

export const LoginForm = (props: Props) => {
  const authCtx = useAuthContext();
  const { t } = useTranslation();
  const notificationCtx = useNotification();

  const loginError =
    authCtx.loginError &&
    typeof authCtx.loginError === 'string' &&
    (authCtx.loginError.includes('invalid_email') ||
      authCtx?.loginError.includes('incorrect_password'));

  useEffect(() => {
    if (loginError) {
      notificationCtx.danger(t(errors.wrongEmailOrPassword), true);
    }
  }, [loginError]);
  const { isLoading, formik } = props;

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup className='form-group'>
        <Input
          type='email'
          name='email'
          className='white'
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder={t(formInputs.email)}
          invalid={!!formik.errors.email && formik.touched.email}
        />
        <FormFeedback>{formik.errors.email}</FormFeedback>
      </FormGroup>
      <FormGroup className='form-group'>
        <Input
          name='password'
          type='password'
          className='white'
          value={formik.values.password}
          onChange={formik.handleChange}
          placeholder={t(formInputs.password)}
          invalid={!!formik.errors.password && formik.touched.password}
        />
        <FormFeedback>{formik.errors.password}</FormFeedback>
      </FormGroup>

      <Button
        type='submit'
        className='Login__submit w-100-mobile px-4 py-2'
        onClick={() => props.formik.submitForm()}
      >
        {isLoading ? t(actions.loggingIn) : 'Login'}
      </Button>

      <div className='Login__links'>
        <span>
          Don&apos;t have a profile yet?{' '}
          <ThemeLink to='/register' className='Login__wrapper-text'>
            Create one!
          </ThemeLink>
        </span>
        <ThemeLink to='/forgot-password' className='Login__wrapper-text'>
          Forgot password
        </ThemeLink>
      </div>
    </Form>
  );
};
