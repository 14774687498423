import React from 'react';
import { PriceRangeSlider } from '../../../shared/PriceRangeSlider/PriceRangeSlider';
import { Spinner } from 'reactstrap';

import './ShopPriceRange.scss';

type Props = {
  loading?: boolean;
  onChange?: ({ min, max }: { min: number; max: number }) => void;
  onMinChange?: (value: number) => void;
  onMaxChange?: (value: number) => void;
};

export const ShopPriceRange = ({ loading, onMinChange, onMaxChange }: Props) => {
  return (
    <div className='ShopPriceRange'>
      <h4 className='ShopPriceRange__label'>
        Price
        {loading && (
          <span className='ShopPriceRange__label_icon'>
            <Spinner className='ShopPriceRange__label_icon_spinner' />
          </span>
        )}
      </h4>
      <PriceRangeSlider min={0} max={10000} onMinChange={onMinChange} onMaxChange={onMaxChange} />
    </div>
  );
};
