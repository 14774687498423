import React from 'react';
import { NavigateProps, Navigate } from 'react-router-dom';
// import { useLanguageContext } from "../../lib/context/LanguageContext/LanguageContext";

export const ThemeRedirect = (props: NavigateProps) => {
  // const langCtx = useLanguageContext();
  // const prefix = langCtx.getLangPrefix();

  return <Navigate {...props} to={props.to} />;
};
