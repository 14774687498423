import React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { actions, fields } from '../../lib/translation/strings';
import { ThemeLink } from '../../components/shared/ThemeLink/ThemeLink';

import './ProfileSidebar.scss';
import { useAuthContext } from '../../lib/context/AuthContext/AuthContext';

export const ProfileSidebar = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { logout } = useAuthContext();

  const handleLogout = () => {
    logout();
  };

  return (
    <div className='ProfileSidebar'>
      <ul className='ProfileSidebar__tabs'>
        <li
          className={cn(
            'ProfileSidebar__tab',
            pathname === '/my-profile' && 'ProfileSidebar__tab--active',
          )}
        >
          <ThemeLink to={'/my-profile/'}>{t(fields.myAccount)}</ThemeLink>
        </li>
        <li
          className={cn(
            'ProfileSidebar__tab',
            pathname === '/my-profile/address' && 'ProfileSidebar__tab--active',
          )}
        >
          <ThemeLink to={'/my-profile/address'}>{t(fields.address)}</ThemeLink>
        </li>
        <li
          className={cn(
            'ProfileSidebar__tab',
            pathname === '/my-profile/security' && 'ProfileSidebar__tab--active',
          )}
        >
          <ThemeLink to={'/my-profile/security'}>{t(fields.security)}</ThemeLink>
        </li>
        <li
          className={cn(
            'ProfileSidebar__tab',
            pathname === '/my-profile/orders' && 'ProfileSidebar__tab--active',
          )}
        >
          <ThemeLink to={'/my-profile/orders'}>{t(fields.myOrders)}</ThemeLink>
        </li>
        <li
          className={cn(
            'ProfileSidebar__tab',
            pathname === '/my-profile/orders' && 'ProfileSidebar__tab--active',
          )}
        >
          <span onClick={handleLogout}>{t(actions.logOut)}</span>
        </li>
      </ul>
    </div>
  );
};
