import React from 'react';

interface Props {
  alt?: string;
  image: string;
  toggleImageModal: () => void;
}

export const ProductSingleSliderItem = (props: Props) => {
  return (
    <div
      className='ProductSingleSlider__item ProductSingleSlider__item--zoomable'
      onClick={props.toggleImageModal}
    >
      <img src={props.image} alt={props.alt} />
    </div>
  );
};
