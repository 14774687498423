import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { errors } from '../../lib/translation/strings';

import { BlogProps } from '../../types/generalTypes';
import { BlogItem } from '../../components/BlogItem/BlogItem';
import { BulkLoader } from '../../components/shared/BulkLoader/BulkLoader';
import { HeadingTitle } from '../../components/shared/HeadingTitle/HeadingTitle';
import { BlogItemLoader } from '../../components/BlogItem/BlogItemLoader';
import { LoadMoreButton } from '../../components/shared/LoadMoreButton/LoadMoreButton';
import { HeadingTitleLoader } from '../../components/shared/HeadingTitle/HeadingTitleLoader';

import { BlogResponse, GET_BLOG_POSTS } from '../../queries/blogQueries';

import './Blog.scss';

export const Blog = () => {
  const postsLimit = 12;
  const { t } = useTranslation();
  const { loading, data, error, fetchMore } = useQuery<BlogResponse>(GET_BLOG_POSTS, {
    variables: {
      limit: postsLimit,
      after: '',
    },
  });
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  if (loading) {
    return (
      <div className='Blog'>
        <Container>
          <HeadingTitleLoader />
          <div className='Blog__list mt-5'>
            <BulkLoader length={8}>
              <BlogItemLoader />
            </BulkLoader>
          </div>
        </Container>
      </div>
    );
  }

  if (error) {
    return (
      <div className='Blog'>
        <Container>{t(errors.wentWrong)}</Container>
      </div>
    );
  }

  const blog = data?.posts.nodes;

  if (blog?.length === 0) {
    return (
      <div className='Blog'>
        <Container>{t(errors.nothingFound)}</Container>
      </div>
    );
  }

  const handleLoadMore = () => {
    setIsLoadingMore(true);

    fetchMore({
      variables: {
        limit: postsLimit,
        after: data?.posts.pageInfo.endCursor,
      },
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newNodes = fetchMoreResult.posts.nodes;
        const pageInfo = fetchMoreResult.posts.pageInfo;

        setIsLoadingMore(false);

        if (!newNodes.length) {
          return previousResult;
        }

        return {
          posts: {
            __typename: previousResult.posts.__typename,
            nodes: [...previousResult.posts.nodes, ...newNodes],
            pageInfo,
          },
        };
      },
    });
  };

  return (
    <div className='Blog'>
      <Container>
        <HeadingTitle title='Blog' />
        <div className='Blog__list'>
          {blog?.map((item: BlogProps) => {
            return (
              <BlogItem
                slug={item.slug}
                title={item.title}
                key={`${item.id}-archive-lisst`}
                image={item?.featuredImage?.node?.sourceUrl}
              />
            );
          })}
        </div>
        {data?.posts.pageInfo.hasNextPage && (
          <LoadMoreButton onClick={() => handleLoadMore()} loading={loading || isLoadingMore} />
        )}
      </Container>
    </div>
  );
};
