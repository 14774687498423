export const getFormatedDate = (date?: string, withHour: boolean = false) => {
  if (!date) {
    return '';
  }

  let formatedDate = '';
  const today: any = new Date(date);
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();

  formatedDate = dd + '/' + mm + '/' + yyyy;

  if (withHour) {
    formatedDate =
      formatedDate +
      '-' +
      checkClockString(today.getHours()) +
      ':' +
      checkClockString(today.getMinutes());
  }

  return formatedDate;
};

const checkClockString = (value: number) => {
  return value < 10 ? '0' + value : value;
};
