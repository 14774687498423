import React from 'react';
import { TopHeader } from './TopHeader/TopHeader';
import { MenuResponsive } from './MenuResponsive/MenuResponsive';
import { MenuContextProvider } from '../../lib/context/MenuContext/MenuContextProvider';

import './Header.scss';

export const Header = () => {
  return (
    <div className='Header'>
      <MenuContextProvider>
        <div className='Header__wrapper'>
          <TopHeader />
        </div>
        <MenuResponsive />
      </MenuContextProvider>
    </div>
  );
};
