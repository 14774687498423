import gql from 'graphql-tag';

export interface CheckoutInputData {
  clientMutationId: string;
  billing: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    address1: string;
    city: string;
    postcode: string;
    country: string;
    state: string;
  };
  shipping?: {
    firstName: string;
    lastName: string;
    address1: string;
    city: string;
    postcode: string;
    state: string;
    country: string;
  };
  account?: {
    username: string;
    password: string;
  };
  customerNote?: string;
  paymentMethod: string;
  shippingMethod: string;
  shipToDifferentAddress: boolean;
  metaData?: { id: string; key: string; value: string }[];
}

export interface CheckoutVariables {
  inputData: CheckoutInputData;
}

export interface CheckoutResponse {
  checkout: {
    redirect?: string;
    order?: {
      id: string;
      databaseId: number;
      orderKey: string;
    };
  };
}

export const CHECKOUT_ORDER = gql`
  mutation checkoutMutation($inputData: CheckoutInput!) {
    checkout(input: $inputData) {
      redirect
      order {
        id
        databaseId
        orderKey
      }
    }
  }
`;
