import React, { useEffect } from 'react';
import { Row, Col, FormGroup, Input, Form, FormFeedback } from 'reactstrap';
import { RegisterFormik } from '../../lib/formik/useRegisterFormik';
import { Button } from '../../components/shared/Button/Button';
import { useAuthContext } from '../../lib/context/AuthContext/AuthContext';
import { useNotification } from '../../lib/context/NotificationContext/NotificationContext';

interface Props {
  loading: boolean;
  formik: RegisterFormik;
}

export const RegisterForm = (props: Props) => {
  const { formik } = props;
  const authCtx = useAuthContext();
  const notification = useNotification();

  useEffect(() => {
    if (authCtx.registerError) {
      notification.danger(authCtx.registerError, true);
    }
  }, [authCtx.registerError]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Input
              type='text'
              id='registerName'
              placeholder='First Name'
              className='form-control'
              onChange={formik.handleChange}
              value={formik.values.registerName}
              invalid={!!formik.errors.registerName && formik.touched.registerName}
            />
            <FormFeedback>{formik.errors.registerName}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Input
              type='text'
              id='registerLastName'
              placeholder='Last Name'
              onChange={formik.handleChange}
              className='form-control-custom'
              value={formik.values.registerLastName}
              invalid={!!formik.errors.registerLastName && formik.touched.registerLastName}
            />
            <FormFeedback>{formik.errors.registerLastName}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Input
              type='email'
              id='registerEmail'
              placeholder='Email'
              onChange={formik.handleChange}
              className='form-control-custom'
              value={formik.values.registerEmail}
              invalid={!!formik.errors.registerEmail && formik.touched.registerEmail}
            />
            <FormFeedback>{formik.errors.registerEmail}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Input
              type='text'
              id='registerTelephone'
              placeholder='Phone'
              onChange={formik.handleChange}
              className='form-control-custom'
              value={formik.values.registerTelephone}
            />
          </FormGroup>
        </Col>
        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Input
              type='text'
              id='registerAddress'
              placeholder='Address'
              onChange={formik.handleChange}
              className='form-control-custom'
              value={formik.values.registerAddress}
              invalid={!!formik.errors.registerAddress && formik.touched.registerAddress}
            />
            <FormFeedback>{formik.errors.registerAddress}</FormFeedback>
          </FormGroup>
        </Col>

        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Input
              type='text'
              id='registerCity'
              placeholder='City'
              onChange={formik.handleChange}
              className='form-control-custom'
              value={formik.values.registerCity}
              invalid={!!formik.errors.registerCity && formik.touched.registerCity}
            />
            <FormFeedback>{formik.errors.registerCity}</FormFeedback>
          </FormGroup>
        </Col>

        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Input
              type='password'
              id='registerPassword'
              placeholder='Password'
              onChange={formik.handleChange}
              className='form-control-custom'
              value={formik.values.registerPassword}
              invalid={!!formik.errors.registerPassword && formik.touched.registerPassword}
            />
            <FormFeedback>{formik.errors.registerPassword}</FormFeedback>
          </FormGroup>
        </Col>
        <Col xs='12' sm='6'>
          <FormGroup className='form-group'>
            <Input
              type='password'
              placeholder='Password Confirmation'
              id='registerConfirmPassword'
              onChange={formik.handleChange}
              className='form-control-custom'
              value={formik.values.registerConfirmPassword}
              invalid={
                !!formik.errors.registerConfirmPassword && formik.touched.registerConfirmPassword
              }
            />
            <FormFeedback>{formik.errors.registerConfirmPassword}</FormFeedback>
          </FormGroup>
        </Col>
        <div className='w-100' />
        <Col xs='12'>
          <FormGroup className='form-group'>
            <Button
              type='submit'
              disabled={props.loading}
              onClick={() => props.formik.submitForm()}
              className='w-100-mobile px-4 py-2'
            >
              Register
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
