/* eslint-disable camelcase */
export const formInputs = {
  name: 'Name',
  email: 'Email',
  address: 'Address',
  message: 'Message',
  firstName: 'First name',
  lastName: 'Last name',
  password: 'Password',
  specialRequest: 'Special request',
  username: 'Username',
  newPassword: 'New password',
  phonePrefix: 'Phone prefix',
  phoneNumber: 'Phone number',
  confirmPassword: 'Confirm password',
  commentPlaceholder: 'Write here your special request',
  fullName: 'Full name',
  category: 'Category',
  appointmentType: 'Appointment type',
  onlineAppointmentType: 'Online appointment type',
};

export const formValidation = {
  country: 'Country is required',
  city: 'City is required',
  zipCode: 'Zip code is required',
  confirmEmail: 'Re-enter email',
  address: 'Address is required',
  firstName: 'First name is required',
  lastName: 'Last name is required',
  email: 'Please enter your email',
  differentEmail: 'Email does not match',
  subject: 'Subject is required',
  message: 'Please write your message',
  validEmail: 'Please enter a valid email',
  phone: 'Phone number is required',
  phonePrefix: 'Phone prefix is required',
  category: 'Category is required',
  appointmentType: 'Appointment type is required',
  onlineAppointmentType: 'Online appointment type is required',
  password: 'Please enter your password',
  passwordNotMatch: 'Passwords do not match',
  paymentMethod: 'Please choose payment method',
  selectVariation: 'Please select one of the options',
  minPasswordLength: 'Password must be at least 5 characters',
  termsAndConditions: 'Please confirm that you agree to the terms and conditions',
};

export const formMessages = {
  profileUpdated: 'Your data has been updated',
  profileNotUpdated: 'Data update is incomplete... please try again',
};

export const actions = {
  login: 'Login',
  save: 'Save',
  show: 'Show',
  send: 'Send',
  sendInquiry: 'Send Inquiry',
  logOut: 'Log out',
  apply: 'Apply',
  remove: 'Remove',
  shop: 'Shop',
  search: 'Search',
  clean: 'Clean',
  filter: 'Filter',
  filters: 'Filters',
  approve: 'Approve',
  register: 'Register',
  orderBy: 'Order by',
  seeMore: 'See more',
  loggingIn: 'Logging in',
  saving: 'Saving',
  sending: 'Sending',
  loading: 'Loading',
  addToBag: 'Add to bag',
  addProducts: 'Add products',
  processing: 'Processing',
  createAccount: 'Create account',
  backToHome: 'Back to home',
  findProduct: 'Search',
  subscribe: 'Subscribe for updates',
  goBackToStores: 'Go back to stores',
  removingCoupon: 'Removing coupon',
  retypePassword: 'Retype password',
  selectYourCity: 'Select your city',
  continueWithPurchase: 'Continue with purchase',
  seeAllResults: 'See all results',
  updatingShippingMethod: 'Updating shipping method',
};

export const fields = {
  price: 'Price',
  city: 'City',
  total: 'Total',
  cart: 'Cart',
  order: 'Order',
  stores: 'Stores',
  address: 'Address',
  status: 'Status',
  events: 'Events',
  subject: 'Subject',
  market: 'Market',
  country: 'Country',
  state: 'State',
  quantity: 'Quantity',
  barcode: 'Barcode',
  myList: 'My list',
  billing: 'Billing',
  seeMore: 'See more',
  product: 'Product',
  shipping: 'Shipping',
  article: 'Article',
  articles: 'Articles',
  contact: 'Contact',
  viewAll: 'View all',
  privacy: 'Privacy',
  subTotal: 'Subtotal',
  zipCode: 'Zip code',
  cuponCode: 'Coupon code',
  transport: 'Transport',
  categories: 'Categories',
  description: 'Description',
  myAccount: 'My account',
  seeCart: 'View cart',
  faq: 'Faq',
  action: 'Action',
  jobVacancy: 'Job vacancy',
  myOrders: 'My orders',
  verification: 'Verification',
  yourAccount: 'Your account',
  orderDate: 'Order date',
  myProducts: 'My products',
  includeTaxes: 'Include taxes',
  relatedProducts: 'Related products',
  shippingCost: 'Shipping cost',
  productType: 'Product Types',
  collection: 'Collection',
  paymentMethod: 'Payment method',
  usedCoupons: 'Used coupons',
  orderDetails: 'Order details',
  termsOfPurchase: 'Terms of purchase',
  bagSummary: 'Bag summary',
  billingAddress: 'Billing address',
  shippingAddress: 'Shipping address',
  orderSummary: 'Order summary',
  shippingMethod: 'Shipping method',
  billingAndShipping: 'Billing and Shipping',
  personalInformation: 'Personal information',
  searchResultsFor: 'Search results for',
  copyrights: 'All rights reserved',
  categorizeByAlphabet: 'Categorize by alphabet',
  male: 'Male',
  female: 'Female',
  children: 'Children',
  all: 'All',
  security: 'Security',
};

export const errors = {
  noStores: 'No stores found',
  noOptions: 'No options available',
  noProducts: 'No products found',
  requiredCoupon: 'Coupon is required',
  noProductsFound: 'No products found',
  noStoresFound: 'Currently this store is not found',
  noProductFound: 'Requested product not found',
  noProductsDescription: 'Currently no products available',
  noOrdersFound: 'You currently have no orders',
  wrongEmailOrPassword: 'Wrong email or password',
  productNotInStock: 'Selected product is not in stock',
  variationOutOfStock: 'Selected option is out of stock',
  customAttributeMissingValues: 'Fill all the required fields for the custom product',
  nothingFound: 'No items found for this category',
  maximumAmount: 'You have reached maximum stock of this product',
  noStoreProducts: 'No products listed in the requested store',
  wentWrong: 'Something went wrong, please try again...!',
  orderNotFound: 'The order you are looking for was not found, please visit the order list',
  cannotAddAmountOfStockToCart: 'Cannot add this amount of stock to cart',
};

export const messages = {
  free: 'Free',
  allRight: 'All right',
  agreeWith: 'I agree with the',
  allStores: 'All stores',
  buyByStore: 'Buy by store',
  purchaseTerms: 'terms and conditions',
  allProducts: 'All products',
  buyInMarket: 'Buy at ValentineCouture',
  pageNotFound: 'This page does not exist',
  buyByCategory: 'Buy by category',
  singleProductAdded: 'Product added',
  forgotPassword: 'Forgot password',
  noFaq: 'Currently there are no faq',
  shippingInformation: 'Shipping information',
  toAnotherAddress: 'Ship to another address',
  contactSuccess: 'Form submitted successfully, thank you for your message!',
  registerSuccess: 'You have successfully registered',
  productAdded: 'You have added a product to your cart',
  noProductOnTheList: 'You currently have no products on the list',
  noProductsOnWishlist: 'You currently have no products on your wishlist',
  resetPasswordSuccess: 'You have successfully changed your password, please log in here',
  orderConfirmed: 'Your order has been confirmed and you will be notified via email shortly',
  resetPasswordDescription: 'Set your new password and then you can log in with new credentials',
  resetPasswordInstruction:
    'in the email you provided here you will receive the link to follow the steps for resetting the password',
  createAccountMessage:
    'in order to facilitate the purchasing process, you need to create an account. Account creation implies providing personal information.',
  resetPasswordMessage:
    'we have sent you an email to guide you on how to reset your password. If you reset your password, then log in with the button below',
  orderIsSubmitting: 'Your order is being submitted, please wait!',
  emptyList: 'Your list is empty',
};
export const texts = {
  sold: 'Sold',
  barcode: 'Barcode',
  cart: 'Cart',
  emptyCart: 'Your cart is empty',
  continueWithOrder: 'Continue with order',
  pa_color: 'Color',
  pa_size: 'Size',
  deliveryReturns: 'Delivery & Returns',
  sizeGuide: 'Size guide',
};
