import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthContext } from '../lib/context/AuthContext/AuthContext';
import { ThemeRedirect } from '../components/shared/ThemeRedirect/ThemeRedirect';

export const PublicRoute = () => {
  const authCtx = useAuthContext();

  if (authCtx.isAuthenticated) {
    return <ThemeRedirect to='/' />;
  }
  return <Outlet />;
};
