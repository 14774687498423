import React from 'react';
import ContentLoader from 'react-content-loader';

export const BookAppointmentPlaceholder = () => {
  return (
    <div className='BookAppointmentPlaceholder'>
      <ContentLoader
        height={500}
        backgroundColor='#f3f3f3'
        foregroundColor='#ecebeb'
        style={{ width: '100%' }}
      >
        <rect x='0' y='0' width='200' height='30' />
        <rect x='0' y='60' rx='20' width='45%' height='40' />
        <rect x='55%' y='60' rx='20' width='45%' height='40' />
        <rect x='0' y='120' rx='20' width='100%' height='108' />
        <rect x='0' y='260' width='200' height='47' />

        <rect x='0' y='360' width='350' height='10' />
        <rect x='0' y='380' width='350' height='10' />
        <rect x='0' y='400' width='300' height='10' />
        <rect x='0' y='420' width='200' height='10' />
      </ContentLoader>
    </div>
  );
};
