import gql from 'graphql-tag';

export const UPDATE_ITEM_QUANTITIES = gql`
  mutation ($input: UpdateItemQuantitiesInput!) {
    updateItemQuantities(input: $input) {
      cart {
        isEmpty
        subtotal
        total
        contents {
          itemCount
        }
      }
      items {
        key
        quantity
        variation {
          node {
            id
            databaseId
            sku
            onSale
            salePrice
            regularPrice
            stockQuantity
            attributes {
              nodes {
                id
                name
                value
              }
            }
          }
        }
        product {
          node {
            id
            slug
            databaseId
            name
            onSale
            type
            image {
              id
              sourceUrl(size: MEDIUM_LARGE)
              mediaItemUrl
              srcSet(size: LARGE)
            }
            ... on SimpleProduct {
              name
              salePrice
              regularPrice
              stockQuantity
            }
            ... on VariableProduct {
              name
              salePrice
              regularPrice
              stockQuantity
            }
          }
        }
      }
    }
  }
`;

export const ADD_ITEM_TO_CART = gql`
  mutation addProduct(
    $clientMutationId: String!
    $productId: Int!
    $quantity: Int
    $variationId: Int
    $extraData: String
  ) {
    addToCart(
      input: {
        clientMutationId: $clientMutationId
        productId: $productId
        quantity: $quantity
        variationId: $variationId
        extraData: $extraData
      }
    ) {
      cart {
        isEmpty
        subtotal
        total
        discountTotal
        shippingTotal
        chosenShippingMethods
        appliedCoupons {
          code
          description
          discountAmount(format: RAW)
          discountTax(format: RAW)
        }
        contents {
          itemCount
          nodes {
            key
            quantity
            extraData {
              id
              key
              value
            }
            variation {
              node {
                id
                databaseId
                sku
                onSale
                salePrice
                regularPrice
                stockQuantity
                attributes {
                  nodes {
                    id
                    name
                    value
                  }
                }
              }
            }
            product {
              node {
                id
                slug
                databaseId
                name
                onSale
                type
                image {
                  id
                  sourceUrl(size: MEDIUM_LARGE)
                  mediaItemUrl
                  srcSet(size: LARGE)
                }
                ... on SimpleProduct {
                  id
                  name
                  salePrice
                  regularPrice
                  stockQuantity
                }
                ... on VariableProduct {
                  id
                  name
                  salePrice
                  regularPrice
                  stockQuantity
                }
              }
            }
          }
        }
      }
    }
  }
`;

export interface UpdateShippingMethodVariables {
  shippingMethods: string[];
}

export interface UpdateShippingMethodResponse {
  updateShippingMethod: {
    cart: {
      total: string;
      shippingTotal: string;
      chosenShippingMethod: string;
    };
  };
}

export const updateShippingMethod = gql`
  mutation updateShippingMethod($shippingMethods: [String]) {
    updateShippingMethod(
      input: {
        clientMutationId: "update-shipping-method-mutation"
        shippingMethods: $shippingMethods
      }
    ) {
      cart {
        total
        shippingTotal
        chosenShippingMethod
      }
    }
  }
`;
