import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { createApolloClient } from './apollo/createApolloClient';
import { CartContextProvider } from './lib/context/CartContext/CartContextProvider';
import { AuthContextProvider } from './lib/context/AuthContext/AuthContextProvider';
import { MenuSidebarContextProvider } from './lib/context/MenuSidebarContext/MenuSidebarContextProvider';
// import { LanguageContextProvider } from "./lib/context/LanguageContext/LanguageContextProvider";
import { App } from './App';

import './styles/main.scss';

const apolloClient = createApolloClient();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.Fragment>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        {/*<LanguageContextProvider>*/}
        <AuthContextProvider>
          <CartContextProvider>
            <MenuSidebarContextProvider>
              <App />
            </MenuSidebarContextProvider>
          </CartContextProvider>
        </AuthContextProvider>
        {/*</LanguageContextProvider>*/}
      </BrowserRouter>
    </ApolloProvider>
  </React.Fragment>,
);

serviceWorker.unregister();
