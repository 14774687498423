import React from 'react';
import ContentLoader from 'react-content-loader';

export const ShopCatalogBannerLoader = () => {
  return (
    <div className='ShopCatalogBanner__wrapper'>
      <div className='ShopCatalogBanner__image'>
        <ContentLoader
          speed={2}
          width={800}
          height={400}
          viewBox='0 0 800 400'
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
        >
          <rect x='0' y='0' rx='0' ry='0' width='800' height='400' />
        </ContentLoader>
      </div>
      <div className='ShopCatalogBanner__content'>
        <h4>
          <ContentLoader
            speed={2}
            width={170}
            height={24}
            viewBox='0 0 170 24'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
          >
            <rect x='0' y='0' rx='0' ry='0' width='170' height='24' />
          </ContentLoader>
        </h4>
        <h1>
          <ContentLoader
            speed={2}
            width={307}
            height={34}
            viewBox='0 0 307 34'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
          >
            <rect x='0' y='0' rx='0' ry='0' width='307' height='34' />
          </ContentLoader>
        </h1>
        <p>
          <ContentLoader
            speed={2}
            width={180}
            height={20}
            viewBox='0 0 180 20'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
          >
            <rect x='0' y='0' rx='0' ry='0' width='180' height='20' />
          </ContentLoader>
        </p>
      </div>
    </div>
  );
};
