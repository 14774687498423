import React from 'react';
import { useTranslation } from 'react-i18next';
import { actions, messages } from '../../lib/translation/strings';
import { HeadingTitle } from '../../components/shared/HeadingTitle/HeadingTitle';
import { DefaultButton } from '../../components/shared/DefaultButton/DefaultButton';
import { ThemeContainer } from '../../components/shared/ThemeContainer/ThemeContainer';

import './Page404.scss';

export const Page404 = () => {
  const { t } = useTranslation();

  return (
    <div className='Page404'>
      <ThemeContainer>
        <HeadingTitle title='404' />
        <div className='Verification__description'>
          <p>{t(messages.pageNotFound)}</p>
        </div>
        <DefaultButton to={'/'} title={t(actions.backToHome)} />
      </ThemeContainer>
    </div>
  );
};
