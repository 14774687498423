export const splitArray = <T>(array?: Array<T>, length: number = 2) => {
  if (!array) {
    return [[], []];
  }
  if (array.length < length) {
    return [array, []];
  } else {
    const firstTwo = array.slice(0, length);
    const rest = array.slice(length);
    return [firstTwo, rest];
  }
};
