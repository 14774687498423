import React from 'react';
import { ThemeLink } from '../shared/ThemeLink/ThemeLink';
import { Product } from '../../types/productTypes';
import { Image } from '../shared/Image/Image';
import { ProductPrices } from '../shared/ProductPrices/ProductPrices';
import { RemoveItemButton } from '../shared/RemoveItemButton/RemoveItemButton';
import { WishListButton } from '../shared/WishListButton/WishListButton';

import './ProductsList.scss';

interface Props {
  big?: boolean;
  product?: Product;
  noWishlistButton?: boolean;
  onRemove?: () => void;
}

export const ProductItem = (props: Props) => {
  const thumbnail = props.big
    ? props.product?.image?.mediaItemUrl
    : props.product?.image?.sourceUrl;

  return (
    <div className={`ProductItem${props.big ? ' ProductItem--big' : ''}`}>
      {props.onRemove && <RemoveItemButton onClick={props.onRemove} />}
      <div className='ProductItem__image'>
        <span className='ProductItem__image__holder'>
          <ThemeLink to={`/product/${props.product?.slug}`}>
            <Image src={props.product?.image?.sourceUrl || thumbnail} alt={props.product?.name} />
          </ThemeLink>
        </span>

        {!props.noWishlistButton && props?.product?.databaseId && (
          <WishListButton id={props.product?.databaseId} />
        )}
      </div>
      <div className='ProductItem__content'>
        <ThemeLink to={`/product/${props.product?.slug}`}>
          <h4 className='ProductItem__content__title'>{props.product?.name}</h4>
        </ThemeLink>
        <ProductPrices
          regular={props.product?.regularPrice ?? ''}
          sale={props.product?.salePrice ?? ''}
        />
      </div>
    </div>
  );
};
