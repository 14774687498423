import React from 'react';
import { Logo } from '../Logo/Logo';
import { UserNavigation } from '../UserNavigation/UserNavigation';
import { Menu } from '../Menu/Menu';
import './TopHeader.scss';

export const TopHeader = () => {
  return (
    <div className='TopHeader'>
      <div className='TopHeader__wrapper'>
        <Logo />
        <Menu />
        <UserNavigation />
      </div>
    </div>
  );
};
