import React from 'react';
import ContentLoader from 'react-content-loader';
import { BulkLoader } from '../../../components/shared/BulkLoader/BulkLoader';
import './MenuResponsive.scss';

export const MenuResponsiveLoader = () => {
  return (
    <div className='MenuResponsive__submenu'>
      <div className='MenuResponsive__submenu__wrapper'>
        <div className='MenuResponsive__submenu__top'>
          <h6>
            <ContentLoader
              speed={2}
              width={140}
              height={20}
              viewBox='0 0 140 20'
              backgroundColor='#f3f3f3'
              foregroundColor='#ecebeb'
            >
              <rect x='0' y='0' rx='0' ry='0' width='140' height='20' />
            </ContentLoader>
          </h6>
          <ul className='MenuResponsive__submenu__list'>
            <BulkLoader length={2}>
              <li className='MenuResponsive__submenu__list-item'>
                <div className='MenuResponsive__submenu__list__item-title'>
                  <ContentLoader
                    speed={2}
                    width={200}
                    height={24}
                    viewBox='0 0 200 24'
                    backgroundColor='#f3f3f3'
                    foregroundColor='#ecebeb'
                  >
                    <rect x='0' y='0' rx='0' ry='0' width='200' height='24' />
                  </ContentLoader>
                </div>
              </li>
            </BulkLoader>
          </ul>
        </div>
        <div className='MenuResponsive__submenu__bottom'>
          <BulkLoader length={2}>
            <div className='MenuResponsive__submenu__box'>
              <div className='MenuResponsive__submenu__box-image'>
                <ContentLoader
                  speed={2}
                  width={200}
                  height={200}
                  viewBox='0 0 200 200'
                  backgroundColor='#f3f3f3'
                  foregroundColor='#ecebeb'
                >
                  <rect x='0' y='0' rx='0' ry='0' width='200' height='200' />
                </ContentLoader>
              </div>
              <div className='MenuResponsive__submenu__box-content'>
                <h5>
                  <ContentLoader
                    speed={2}
                    width={200}
                    height={20}
                    viewBox='0 0 200 20'
                    backgroundColor='#f3f3f3'
                    foregroundColor='#ecebeb'
                  >
                    <rect x='0' y='0' rx='0' ry='0' width='200' height='20' />
                  </ContentLoader>
                </h5>
                <span>
                  <ContentLoader
                    speed={2}
                    width={100}
                    height={16}
                    viewBox='0 0 100 16'
                    backgroundColor='#f3f3f3'
                    foregroundColor='#ecebeb'
                  >
                    <rect x='0' y='0' rx='0' ry='0' width='100' height='16' />
                  </ContentLoader>
                </span>
              </div>
            </div>
          </BulkLoader>
        </div>
      </div>
    </div>
  );
};
