import React from 'react';
import { ThemeLink } from '../shared/ThemeLink/ThemeLink';
import LogoWhiteImage from '../../assets/logo.svg';
import LogoBlackImage from '../../assets/logo-black.svg';

interface Props {
  noLink?: boolean;
  black?: boolean;
}

export const Logo = ({ noLink, black }: Props) => {
  return (
    <div className='Logo'>
      {noLink ? (
        <img src={black ? LogoBlackImage : LogoWhiteImage} alt='Valentine Couture' />
      ) : (
        <ThemeLink to='/'>
          <img src={black ? LogoBlackImage : LogoWhiteImage} alt='Valentine Couture' />
        </ThemeLink>
      )}
    </div>
  );
};
