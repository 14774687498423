import React from 'react';
import ContentLoader from 'react-content-loader';

export const HomeProcessesLoader = () => {
  return (
    <div className='HomeProcesses__process'>
      <div className='HomeProcesses__process-img'>
        <ContentLoader
          speed={2}
          width={80}
          height={60}
          viewBox='0 0 80 60'
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
        >
          <rect x='0' y='0' rx='0' ry='0' width='80' height='60' />
        </ContentLoader>
      </div>
      <div className='HomeProcesses__process-content'>
        <h6>
          <ContentLoader
            speed={2}
            width={200}
            height={25}
            viewBox='0 0 200 25'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
          >
            <rect x='0' y='0' rx='0' ry='0' width='200' height='25' />
          </ContentLoader>
        </h6>
        <p>
          <ContentLoader
            speed={2}
            width={120}
            height={20}
            viewBox='0 0 120 20'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
          >
            <rect x='0' y='0' rx='0' ry='0' width='120' height='20' />
          </ContentLoader>
        </p>
      </div>
    </div>
  );
};
