import React, { useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { ApolloErrorOptions } from '@apollo/client/errors';

import { useNotification } from '../../../lib/context/NotificationContext/NotificationContext';

interface Props {
  error?: ApolloErrorOptions | ApolloError;
  errorComponent?: JSX.Element;
  children: React.ReactNode;
}

export const ApolloErrorGuard = (props: Props) => {
  const notificationCtx = useNotification();

  if (!props.error) {
    return <>{props.children}</>;
  }

  if (props.errorComponent) {
    return <>{props.errorComponent}</>;
  }

  useEffect(() => {
    if (!props.error) return;
    notificationCtx.danger(props.error.toString());
  }, [props.error]);

  return <></>;
};
