import { FormikValues } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { BlockButton } from '../../components/shared/BlockButton/BlockButton';
import { actions, fields, formInputs, messages } from '../../lib/translation/strings';
import { SelectCountry } from '../../components/shared/SelectCountry/SelectCountry';
import { CONTACT_FORM_SUBJECTS } from '../../constants';
import { Alert } from '../../components/Notifications/Alert';

interface Props {
  loading: boolean;
  formik: FormikValues;
  success?: boolean;
  error?: string;
}

export const ContactForm = ({ formik, loading, success, error }: Props) => {
  const { t } = useTranslation();

  return (
    <div className='ContactForm'>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs='12' sm='6'>
            <FormGroup className='form-group'>
              <Label for='first_name'>{t(formInputs.firstName)}</Label>
              <Input
                id='first_name'
                type='text'
                name='first_name'
                value={formik.values.first_name}
                onChange={formik.handleChange}
                className='form-control-custom'
                invalid={!!formik.errors.first_name && formik.touched.first_name}
              />
              <FormFeedback>{formik.errors.first_name}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs='12' sm='6'>
            <FormGroup className='form-group'>
              <Label for='last_name'>{t(formInputs.lastName)}</Label>
              <Input
                id='last_name'
                type='text'
                name='last_name'
                value={formik.values.last_name}
                onChange={formik.handleChange}
                className='form-control-custom'
                invalid={!!formik.errors.last_name && formik.touched.last_name}
              />
              <FormFeedback>{formik.errors.last_name}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs='12' sm='6'>
            <FormGroup className='form-group'>
              <Label for='email'>{t(formInputs.email)}</Label>
              <Input
                id='email'
                type='email'
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                className='form-control-custom'
                invalid={!!formik.errors.email && formik.touched.email}
              />
              <FormFeedback>{formik.errors.email}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs='12' sm='6'>
            <FormGroup className='form-group'>
              <Label for='phone'>{t(formInputs.phoneNumber)}</Label>
              <Input
                id='phone'
                type='text'
                name='phone'
                value={formik.values.phone}
                onChange={formik.handleChange}
                className='form-control-custom'
                invalid={!!formik.errors.phone && formik.touched.phone}
              />
              <FormFeedback>{formik.errors.phone}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs='12' sm='6'>
            <FormGroup className='form-group'>
              <Label for='city'>{t(fields.city)}</Label>
              <Input
                id='city'
                type='text'
                name='city'
                value={formik.values.city}
                onChange={formik.handleChange}
                className='form-control-custom'
                invalid={!!formik.errors.city && formik.touched.city}
              />
              <FormFeedback>{formik.errors.city}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs='12' sm='6'>
            <FormGroup className='form-group'>
              <Label for='country'>{t(fields.country)}</Label>
              <SelectCountry
                name='country'
                defaultOptionLabel=' '
                initialValue={formik.values.country}
                hasError={!!formik.errors.country && formik.touched.country}
                errorMessage={formik.errors.country}
                onSelect={(country: string) => {
                  formik.setFieldValue('country', country);
                  formik.setFieldTouched('country');
                }}
              />
            </FormGroup>
          </Col>

          <Col xs='12'>
            <FormGroup className='form-group'>
              <Label for='subject'>{t(fields.subject)}</Label>
              <Input
                id='subject'
                type='select'
                placeholder='Subject'
                className='form-control-custom form-control'
                invalid={!!formik.errors.subject && formik.touched.subject}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue('subject', e.target.value);
                  formik.setFieldTouched('subject');
                }}
              >
                <option disabled selected></option>
                {CONTACT_FORM_SUBJECTS.map(({ label, value }) => (
                  <option key={`${value}-subject`} value={value}>
                    {label}
                  </option>
                ))}
              </Input>
              <FormFeedback>{formik.errors.subject}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs='12' sm='12'>
            <FormGroup className='form-group'>
              <Label for='message'>{t(formInputs.message)}</Label>
              <Input
                id='message'
                type='textarea'
                name='message'
                value={formik.values.message}
                className='form-control-custom'
                invalid={!!formik.errors.message && formik.touched.message}
                onChange={formik.handleChange}
              />
              <FormFeedback>{formik.errors.message}</FormFeedback>
            </FormGroup>
          </Col>
          <Col xs='12'>
            {success && <Alert message={t(messages.contactSuccess)} type='success' />}
            {error && <Alert message={error.replace('GraphQL error: ', '')} type='danger' />}
            <FormGroup>
              <BlockButton
                type='submit'
                disabled={loading}
                className='Contact__btn_submit w-100-mobile'
              >
                {loading ? t(actions.saving) : t(actions.send)}
              </BlockButton>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
