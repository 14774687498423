import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Input, FormFeedback } from 'reactstrap';
import { errors } from '../../../lib/translation/strings';
import { GET_STATES, StatesResponse } from '../../../queries/generalQueries';
import { useLazyQuery } from '@apollo/client';

interface Props {
  id?: string;
  country?: string;
  name: string;
  initialValue?: string;
  hasError: boolean | undefined;
  onSelect: (v: string) => void;
  errorMessage: string | undefined;
  onComplete?: (state: boolean) => void;
}

export const SelectState = (props: Props) => {
  const [loadStates, { data, loading, error }] = useLazyQuery<StatesResponse>(GET_STATES);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.onComplete) {
      props.onComplete(loading);
    }
  }, [loading]);

  useEffect(() => {
    if (!props.country) return;
    loadStates({ variables: { country: props.country } });
  }, [props.country]);

  if (error) {
    return <p>{t(errors.wentWrong)}</p>;
  }

  return (
    <FormGroup>
      <Input
        disabled={loading}
        type='select'
        placeholder='State'
        id={props.id}
        invalid={props.hasError}
        className='form-control-custom form-control'
        onChange={(e) => props.onSelect(e.target.value)}
        defaultValue={props.initialValue ? props.initialValue : ''}
      >
        <option value=''>{loading ? 'Loading...' : 'Select State'}</option>
        {data?.countryStates.map((item) => (
          <option
            key={`${item.name}-state`}
            value={item.code}
            selected={props.initialValue === item.code}
          >
            {item.name}
          </option>
        ))}
      </Input>
      <FormFeedback>{props.errorMessage}</FormFeedback>
    </FormGroup>
  );
};
