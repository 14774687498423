import gql from 'graphql-tag';

export type BlogPost = {
  id: string;
  featuredImage: {
    node: {
      sourceUrl: string;
    };
  };
  excerpt: string;
  slug: string;
  title: string;
};

export type BlogResponse = {
  posts: {
    nodes: BlogPost[];
    pageInfo: {
      endCursor: string;
      hasNextPage: boolean;
    };
  };
};

export const GET_BLOG_POSTS = gql`
  query getBlogPosts($limit: Int, $after: String) {
    posts(first: $limit, after: $after) {
      nodes {
        id
        featuredImage {
          node {
            sourceUrl(size: MEDIUM_LARGE)
          }
        }
        excerpt
        slug
        title
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export type BlogPostResponse = {
  postBy: {
    featuredImage: {
      node: {
        id: string;
        sourceUrl: string;
      };
    };
    excerpt: string;
    slug: string;
    title: string;
    content: string;
  };
};

export const GET_BLOG_POST = gql`
  query getBlogPost($slug: String) {
    postBy(slug: $slug) {
      featuredImage {
        node {
          id
          sourceUrl(size: LARGE)
        }
      }
      excerpt
      slug
      title
      content
    }
  }
`;
