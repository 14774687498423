import React, { useRef } from 'react';
import cs from 'classnames';
import { ApolloErrorGuard } from '../../../shared/ApolloErrorGuard/ApolloErrorGuard';
import { ShopAttributes } from '../ShopAttributes';
import { useShopHandlers } from '../../../../lib/hooks/useShopHandlers';
import { ShopSelect } from '../ShopSelect/ShopSelect';
import { useShopContext } from '../../../../lib/context/ShopContext/ShopContext';
import { useShopFilterData } from '../../../../lib/hooks/useShopFilterData';
import { ShopCategories } from '../ShopCategories';
import { HandleLoadingState } from '../../../shared/HandleLoadingState/HandleLoadingState';
import { ShopSidebarPlaceholder } from '../ShopSidebarPlaceholder';
import { BulkLoader } from '../../../shared/BulkLoader/BulkLoader';
import { actions, fields } from '../../../../lib/translation/strings';
import { useTranslation } from 'react-i18next';
import { useOutsideClickDetection } from '../../../../lib/hooks/useOutsideClickDetection';
import { Icon } from '../../../shared/Icon/Icon';
import { ProductTaxonomyEnum } from '../../../../types/generalTypes';
import { ShopPriceRange } from '../ShopPriceRange/ShopPriceRange';

import './ShopSidebar.scss';

interface Props {
  className?: string;
}

export const ShopSidebar = (props: Props) => {
  const { t } = useTranslation();
  const {
    data,
    sidebar: { isOpened, closeSidebar },
  } = useShopContext();
  const shopHandlers = useShopHandlers();
  const shopFilterData = useShopFilterData();

  const ref = useRef(null);

  useOutsideClickDetection(ref, () => closeSidebar());

  const handleOnCategoryChange = (categorySlug: string) => {
    const pathname = categorySlug
      ? `/${shopFilterData?.redirectLinkPrefix}/${categorySlug}`
      : `/${shopFilterData?.redirectLinkPrefix}`;
    shopFilterData?.handleCategoryChange(categorySlug || '');
    shopHandlers.updatePathname(pathname, true);
  };

  return (
    <div
      ref={ref}
      className={cs('ShopSidebar', isOpened && 'ShopSidebar--opened', props.className)}
    >
      <div className='ShopSidebar-head'>
        <p>{t(actions.filters)}</p>
        <span role='button' onClick={closeSidebar}>
          <Icon icon='x' />
        </span>
      </div>
      <div className='ShopSidebar-body'>
        <ApolloErrorGuard error={shopFilterData.error}>
          {!data?.category ? (
            <HandleLoadingState
              loading={shopFilterData.loading}
              loadingPlaceholder={
                <BulkLoader length={5}>
                  <ShopSidebarPlaceholder />
                </BulkLoader>
              }
            >
              <ShopCategories
                categories={shopFilterData.categories}
                onCategoryChange={handleOnCategoryChange}
              />
            </HandleLoadingState>
          ) : (
            <>
              <ShopSelect
                attribute={ProductTaxonomyEnum.PRODUCTCATEGORY}
                loading={shopFilterData?.loading}
                disableMultiselect
                hideIfNoOptions
                label={t(fields.collection)}
                className='ShopSidebar__product_type'
                options={shopFilterData?.subCategoryOptions}
                selected={
                  shopFilterData?.selectedSubCategory
                    ? [shopFilterData?.selectedSubCategory]
                    : undefined
                }
                onSelect={(selectOption) => {
                  const pathName = selectOption
                    ? `/${shopFilterData?.redirectLinkPrefix}/${data?.category}/${selectOption?.value}`
                    : `/${shopFilterData?.redirectLinkPrefix}/${data?.category}`;
                  shopFilterData?.handleCategoryChange(selectOption?.value || '');
                  shopHandlers.updatePathname(pathName, true);
                }}
              />

              <ApolloErrorGuard error={shopFilterData?.attributes.error}>
                {shopFilterData?.attributesData.map((attr, index) => {
                  const slug = ProductTaxonomyEnum[attr.slug as keyof typeof ProductTaxonomyEnum];
                  return (
                    <ShopAttributes
                      key={`${slug}-${index}`}
                      loading={shopFilterData?.attributes.loading}
                      isLastAttribute={shopFilterData?.attributesData.length - 1 === index}
                      attributeTitle={attr.label}
                      attribute={slug}
                      options={attr?.terms.map((t) => ({
                        ...t,
                        value: t.slug,
                        label: t.name,
                      }))}
                      onChange={(attr, selectedValue) =>
                        shopFilterData?.handleAttributesOnChange(attr, selectedValue)
                      }
                    />
                  );
                })}
              </ApolloErrorGuard>

              <ShopPriceRange
                loading={shopFilterData.loading || shopFilterData.attributes.loading}
                onMinChange={(min) => {
                  shopFilterData?.handlePriceOnChange('minPrice', min);
                }}
                onMaxChange={(max) => {
                  shopFilterData?.handlePriceOnChange('maxPrice', max);
                }}
              />
            </>
          )}
        </ApolloErrorGuard>
      </div>
    </div>
  );
};
