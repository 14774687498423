import gql from 'graphql-tag';

export interface HomeVideoResponse {
  generalOption: {
    homeSettings: {
      banner: {
        node: {
          id: string;
          mediaItemUrl: string;
        };
      };
      bannerButton?: {
        url?: string;
        title?: string;
        target?: string;
      };
    };
  };
}

export const getHomeVideo = gql`
  query getHomeVideo {
    generalOption {
      homeSettings {
        banner {
          node {
            id
            mediaItemUrl
          }
        }
        bannerButton {
          url
          title
          target
        }
      }
    }
  }
`;
export interface PrimaryCollectionResponse {
  generalOption: {
    homeSettings: {
      primaryCollection: {
        title: string;
        subTitle: string;
        description: string;
        button: {
          url: string;
          title: string;
        };
        products?: {
          nodes: {
            id: string;
            slug: string;
            name: string;
            image: {
              id: string;
              sourceUrl: string;
            };
          }[];
        };
      };
    };
  };
}

export const getPrimaryCollection = gql`
  query getPrimaryCollection {
    generalOption {
      homeSettings {
        primaryCollection {
          title
          subTitle
          description
          button {
            url
            title
          }
          products {
            nodes {
              id
              slug
              ... on VariableProduct {
                name
                image {
                  id
                  sourceUrl(size: MEDIUM_LARGE)
                }
              }
              ... on SimpleProduct {
                name
                image {
                  id
                  sourceUrl(size: MEDIUM_LARGE)
                }
              }
            }
          }
        }
      }
    }
  }
`;
export interface SecondaryCollectionResponse {
  generalOption: {
    homeSettings: {
      secondaryCollection: {
        title: string;
        subTitle: string;
        button: {
          url: string;
          title: string;
        };
        products?: {
          nodes: {
            id: string;
            slug: string;
            name: string;
            image: {
              id: string;
              sourceUrl: string;
            };
          }[];
        };
      };
    };
  };
}

export const getSecondaryCollection = gql`
  query getSecondaryCollection {
    generalOption {
      homeSettings {
        secondaryCollection {
          title
          subTitle
          button {
            url
            title
          }
          products {
            nodes {
              id
              slug
              ... on VariableProduct {
                name
                image {
                  id
                  sourceUrl(size: MEDIUM_LARGE)
                }
              }
              ... on SimpleProduct {
                name
                image {
                  id
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;

export interface HomeOtherCollectionsResponse {
  generalOption: {
    homeSettings: {
      otherCollections: {
        collections: {
          title: string;
          link: string;
          image: {
            node: {
              id: string;
              sourceUrl: string;
            };
          };
        }[];
      };
    };
  };
}
export const getHomeOtherCollections = gql`
  query getHomeOtherCollections {
    generalOption {
      homeSettings {
        otherCollections {
          collections {
            title
            link
            image {
              node {
                id
                sourceUrl(size: MEDIUM_LARGE)
              }
            }
          }
        }
      }
    }
  }
`;

export interface HomeProcessesResponse {
  generalOption: {
    homeSettings: {
      processes: {
        list: {
          title: string;
          description: string;
          icon?: {
            node?: {
              id: string;
              sourceUrl: string;
            };
          };
        }[];
      };
    };
  };
}
export const getHomeProcesses = gql`
  query getHomeProcesses {
    generalOption {
      homeSettings {
        processes {
          list {
            title
            description
            icon {
              node {
                id
                sourceUrl(size: MEDIUM_LARGE)
              }
            }
          }
        }
      }
    }
  }
`;
