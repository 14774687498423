import { useFormik, FormikHelpers, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { formValidation } from '../../translation/strings';

import { ProfilePasswordFields } from '../../../components/ProfilePages/ProfilePassword/ProfilePassword';

interface UseProfilePasswordOptions {
  initialValues?: ProfilePasswordFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<ProfilePasswordFields>
  ) => Promise<any>;
}

export const useProfilePasswordFormik = (opts: UseProfilePasswordOptions) => {
  const {t} = useTranslation();

  const ProfilePasswordSchema = Yup.object().shape({
    profilePassword: Yup.string()
      .min(5, t(formValidation.minPasswordLength))
      .required(t(formValidation.password)),
    confirmProfilePassword: Yup.string().oneOf(
      [Yup.ref('profilePassword')],
      t(formValidation.passwordNotMatch)
    ),
  });
  

  return useFormik({
    initialValues: {
      profilePassword: '',
      confirmProfilePassword: '',
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ProfilePasswordSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};

export type ProfilePasswordFormik = ReturnType<typeof useProfilePasswordFormik>;
