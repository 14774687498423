import React from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';

import { useLanguageContext } from '../lib/context/LanguageContext/LanguageContext';

import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
import { AppRoutes, RouteType } from './AppRoutes';

import { Page404 } from '../pages/Page404/Page404';

export const Routes = () => {
  const langCtx = useLanguageContext();
  const langPrefix = langCtx.getLangPrefix();

  return (
    <RouterRoutes>
      {AppRoutes.map((r) => {
        const { type, ...rest } = r;

        if (type === RouteType.PUBLIC) {
          return (
            <Route key={`${r.path}`} path={`${langPrefix}/${r.path}`} element={<PublicRoute />}>
              <Route {...rest} path={`${langPrefix}/${r.path}`} />
            </Route>
          );
        }

        if (type === RouteType.PRIVATE) {
          return (
            <Route key={`${r.path}`} path={`${langPrefix}/${r.path}`} element={<PrivateRoute />}>
              <Route {...rest} path={`${langPrefix}/${r.path}`} />
            </Route>
          );
        }

        return <Route {...rest} key={`${r.path}`} path={`${langPrefix}/${r.path}`} />;
      })}

      <Route path='*' element={<Page404 />} />
    </RouterRoutes>
  );
};
