import React from 'react';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useCartContext } from '../../lib/context/CartContext/CartContext';
import { actions, fields, messages } from '../../lib/translation/strings';
import { CartList } from './CartList';
import { CartTotal } from './CartTotal';
import { CartItemLoader } from '../../components/CartItem/CartItemLoader';
import { EmptyPage } from '../../components/shared/EmptyMessage/EmptyPage';
import { BulkLoader } from '../../components/shared/BulkLoader/BulkLoader';
import { HeadingTitle } from '../../components/shared/HeadingTitle/HeadingTitle';
import { HeadingTitleLoader } from '../../components/shared/HeadingTitle/HeadingTitleLoader';
import { HandleLoadingState } from '../../components/shared/HandleLoadingState/HandleLoadingState';

import './Cart.scss';

export const Cart = () => {
  const { t } = useTranslation();
  const cartCtx = useCartContext();

  return (
    <div className='Cart mt-heading'>
      <Container className='p-0-mobile'>
        <HandleLoadingState
          loading={cartCtx.isLoading}
          loadingPlaceholder={
            <>
              <HeadingTitleLoader />
              <div className='CartList mt-5'>
                <BulkLoader length={8}>
                  <CartItemLoader />
                </BulkLoader>
              </div>
            </>
          }
        >
          {cartCtx.error ? null : cartCtx.isEmpty ? (
            <EmptyPage
              title={t(fields.cart)}
              buttonText={t(actions.addProducts)}
              description={t(messages.noProductOnTheList)}
            />
          ) : (
            <>
              <HeadingTitle icon='cart' title={t(fields.cart)} />
              <CartList products={cartCtx.items} />
              <CartTotal />
            </>
          )}
        </HandleLoadingState>
      </Container>
    </div>
  );
};
