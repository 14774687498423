import React from 'react';
import ContentLoader from 'react-content-loader';

import './HomeVideoLoader.scss';

export const HomeVideoLoader = () => {
  return (
    <div className='HomeVideoLoader'>
      <ContentLoader
        speed={2}
        width='100%'
        height='100vh'
        backgroundColor='#f3f3f3'
        foregroundColor='#e7e3e3'
        className='HomeVideoLoader__full'
      >
        <rect x='0' y='0' width='100%' height='100vh' />
      </ContentLoader>
    </div>
  );
};
