import React from 'react';
import './HomeSecondaryCollection.scss';
import { ApolloErrorGuard } from '../../shared/ApolloErrorGuard/ApolloErrorGuard';
import { useQuery } from '@apollo/client';
import { SecondaryCollectionResponse, getSecondaryCollection } from '../../../queries/home';
import { HandleLoadingState } from '../../shared/HandleLoadingState/HandleLoadingState';
import { ThemeLink } from '../../shared/ThemeLink/ThemeLink';
import { Button } from '../../shared/Button/Button';
import Slider from 'react-slick';
import { HomeSecondaryCollectionLoader } from './HomeSecondaryCollectionLoader';

const SLIDER_SETTINGS = {
  slidesToScroll: 1,
  arrows: false,
  infinite: true,
  autoplay: true,
  variableWidth: true,
  autoplaySpeed: 3000,
  easing: 'linear',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        variableWidth: false,
        slidesToShow: 1,
      },
    },
  ],
};

export const HomeSecondaryCollection = () => {
  const { loading, error, data } = useQuery<SecondaryCollectionResponse>(getSecondaryCollection);

  const secondaryCollection = data?.generalOption.homeSettings.secondaryCollection;

  return (
    <HandleLoadingState loading={loading} loadingPlaceholder={<HomeSecondaryCollectionLoader />}>
      <ApolloErrorGuard error={error}>
        <div className='HomeSecondaryCollection'>
          <div className='HomeSecondaryCollection__left'>
            <h5>{secondaryCollection?.subTitle}</h5>
            <h3>{secondaryCollection?.title}</h3>
            <Button to={secondaryCollection?.button.url}>
              {secondaryCollection?.button.title}
            </Button>
          </div>
          <div className='HomeSecondaryCollection__products'>
            <Slider {...SLIDER_SETTINGS}>
              {secondaryCollection?.products?.nodes?.map((product) => {
                return (
                  <ThemeLink
                    key={product.id}
                    to={`/product/${product.slug}`}
                    className='HomeSecondaryCollection__product'
                  >
                    <div className='HomeSecondaryCollection__product-img'>
                      <img src={product.image.sourceUrl} alt={product.name} />
                    </div>
                    <h6>{product.name}</h6>
                  </ThemeLink>
                );
              })}
            </Slider>
          </div>
          <div className='HomeSecondaryCollection__footer'>
            <Button to={secondaryCollection?.button.url}>
              {secondaryCollection?.button.title}
            </Button>
          </div>
        </div>
      </ApolloErrorGuard>
    </HandleLoadingState>
  );
};
