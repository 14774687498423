import React from 'react';
import { Container } from 'reactstrap';
import { HomeVideo } from '../../components/home/HomeVideo/HomeVideo';
import { HomePrimaryCollection } from '../../components/home/HomePrimaryCollection/HomePrimaryCollection';
import { HomeSecondaryCollection } from '../../components/home/HomeSecondaryCollection/HomeSecondaryCollection';
import { HomeOtherCollections } from '../../components/home/HomeOtherCollections/HomeOtherCollections';
import { HomeProcesses } from '../../components/home/HomeProcesses/HomeProcesses';

import './Home.scss';

export const Home = () => {
  return (
    <div className='Home'>
      <HomeVideo />
      <Container>
        <HomePrimaryCollection />
      </Container>
      <HomeSecondaryCollection />
      <Container>
        <HomeOtherCollections />
        <HomeProcesses />
      </Container>
    </div>
  );
};
