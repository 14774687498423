import React from 'react';
import ContentLoader from 'react-content-loader';
import { ProductPricesLoader } from '../shared/ProductPrices/ProductPricesLoader';
import './ProductsList.scss';

interface Props {
  big?: boolean;
  noWishlistButton?: boolean;
}

export const ProductItemLoader = (props: Props) => {
  return (
    <div className={`ProductItem ProductItem__Loader${props.big ? ' ProductItem--big' : ''}`}>
      <div className='ProductItem__image'>
        <span className='ProductItem__image__holder'>
          <ContentLoader
            speed={2}
            width={300}
            height={400}
            viewBox='0 0 300 400'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
          >
            <rect x='0' y='0' width='300' height='400' />
          </ContentLoader>
        </span>
      </div>
      <div className='ProductItem__content ProductItem__content--loader'>
        <h4 className='ProductItem__content__title'>
          <ContentLoader
            speed={2}
            width={200}
            height={24}
            viewBox='0 0 200 24'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
          >
            <rect x='0' y='0' width='200' height='24' />
          </ContentLoader>
        </h4>
        <ProductPricesLoader />
      </div>
    </div>
  );
};
