import React from 'react';
import { BulkLoader } from '../../shared/BulkLoader/BulkLoader';
import { ProductItemLoader } from '../../ProductsList/ProductItemLoader';
import ContentLoader from 'react-content-loader';
import './HomePrimaryCollection.scss';

export const HomePrimaryCollectionLoader = () => {
  return (
    <div className='HomePrimaryCollection'>
      <div className='HomePrimaryCollection__header'>
        <BulkLoader length={3}>
          <ContentLoader
            speed={2}
            width={300}
            height={20}
            viewBox='0 0 300 20'
            backgroundColor='#f3f3f3'
            foregroundColor='#ecebeb'
          >
            <rect x='0' y='0' rx='0' ry='0' width='300' height='20' />
          </ContentLoader>
        </BulkLoader>
      </div>
      <div className='HomePrimaryCollection__products'>
        <BulkLoader length={3}>
          <ProductItemLoader noWishlistButton />
        </BulkLoader>
      </div>
      <div className='HomePrimaryCollection__footer'>
        <ContentLoader
          speed={2}
          width={212}
          height={46}
          viewBox='0 0 212 46'
          backgroundColor='#f3f3f3'
          foregroundColor='#ecebeb'
        >
          <rect x='0' y='0' rx='0' ry='0' width='212' height='46' />
        </ContentLoader>
      </div>
    </div>
  );
};
